/* screen - homepage-main */

.homepage-main {
    align-items: flex-start;
    background-color: var(--white);
    border: 1px none;
    display: flex;
    flex-direction: column;
    height: 4793px;
    /* overflow: hidden; */
    width: 1512px;
}

.homepage-main .homepage {
    align-items: flex-start;
    display: flex;
    height: 80vh;
    margin-top: 1px;
    /* min-width: 1512px; */
}

.homepage-main .overlap-group3 {
    height: 80vh;
    margin-top: -1.0px;
    position: relative;
    width: 1512px;
}

.homepage-main .hero-image {
    align-items: flex-start;
    background-color: var(--jade);
    display: flex;
    height: 80vh;
    left: 264px;
    overflow: hidden;
    position: absolute;
    top: -264px;
    transform: rotate(90deg);
}

.homepage-main .overlap-group2 {
    height: 80vh;
    margin-left: -1px;
    margin-top: 0;
    position: relative;
}

.homepage-main .full-bleed-image {
    align-items: flex-end;
    background: linear-gradient(180deg, rgb(74, 160, 140) 28.12%, rgba(74, 160, 140, 0) 60.42%);
    display: flex;
    height: 984px;
    left: -263px;
    overflow: hidden;
    position: absolute;
    top: 264px;
    transform: rotate(90.00deg);
    width: 1512px;
}

.homepage-main .overlap-group {
    height: 984px;
    margin-bottom: 0;
    position: relative;
    width: 1512px;
}

.homepage-main .hero-image-1 {
    align-items: flex-start;
    background-color: var(--jade);
    display: flex;
    height: 80vh;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
}

.homepage-main .overlap-group1 {
    height: 80vh;
    margin-top: 0;
    position: relative;
}

.homepage-main .full-bleed-image-1 {
    align-items: flex-end;
    background: linear-gradient(180deg, rgb(74, 160, 140) 28.12%, rgba(74, 160, 140, 0) 60.42%);
    display: flex;
    height: 80vh;
    left: -264px;
    padding: 1.0px 0.0px;
    position: absolute;
    top: 264px;
    transform: rotate(90.00deg);
    width: 1512px;
}

.homepage-main .unsplash-container {
    height: 80vh;
    position: relative;
    width: 1512px;
}

.homepage-main .david-marcu-78-a265w-pi-o4-unsplash-1-1 {
    height: 982px;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-180.00deg);
    width: 1512px;
}

.homepage-main .unsplash-container-item {
    height: 80vh;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    transform: rotate(-180.00deg);
    width: 1512px;
}

.homepage-main .graphic {
    left: 90px;
    position: absolute;
    top: 50%; /* Move the element down by 50% of its parent's height */
    left: 50%; /* Move the element right by 50% of its parent's width */
    transform: translate(-50%, -50%); /* Center horizontally and rotate */

}

.homepage-main .grad-for-nav-1 {
    background: linear-gradient(180deg, rgb(7.82, 3.4, 25.5) 19.27%, rgba(0, 0, 0, 0) 100%);
    height: 170px;
    left: 142px;
    opacity: 0.75;
    position: absolute;
    top: 671px;
    transform: rotate(90.00deg);
    width: 1512px;
}

.homepage-main .headline {
    letter-spacing: 0;
    line-height: 88px;
    position: absolute;
    text-align: center;
    max-width: 603px;
    top: 53%; /* Move the element down by 50% of its parent's height */
    left: 50%; /* Move the element right by 50% of its parent's width */
    transform: translate(-50%, -50%)
}

.homepage-main .a-joint-initiative-o {
    height: 25px;
    position: absolute;
    /* top: 910px; */
    width: 759px;
    left: 50%; /* Move the element right by 50% of its parent's width */
    bottom:0;

    transform: translate(-50%, -50%)
}

.homepage-main .nav {
    align-items: flex-start;
    display: flex;
    gap: 528px;
    height: 95px;
    left: 0;
    padding: 1px 64px;
    position: fixed;
    top: 41px;
}

.homepage-main .navbar {
    align-items: center;
    align-self: center;
    display: flex;
    height: 37px;
    margin-bottom: 16.0px;
    min-width: 564px;
    padding: 1.3px 0;
}

.homepage-main .navbar-link-about {
    margin-left: 60px;
    min-width: 64px;
}

.homepage-main .navbar-link-place {
    margin-left: 60px;
    min-width: 82px;
}

.homepage-main .navbar-link-login {
    margin-left: 60px;
    min-width: 57px;
}

.homepage-main .overlap-group1-1 {
    align-self: flex-start;
    border-radius: 16.55px;
    height: 33px;
    margin-left: 61px;
    position: relative;
    width: 33px;
}

.homepage-main .ellipse-container {
    height: 19px;
    left: 8px;
    position: absolute;
    top: 7px;
    width: 16px;
}

.homepage-main .ellipse-11 {
    border: 1.32px solid;
    border-color: var(--white);
    border-radius: 6.17px;
    height: 12px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 12px;
}

.homepage-main .ellipse-12 {
    border: 1.32px solid;
    border-color: var(--white);
    border-radius: 16.55px;
    height: 33px;
    left: 0;
    position: absolute;
    top: 0;
    width: 33px;
}

.homepage-main .intro-block {
    align-items: center;
    background-color: var(--snuff);
    display: flex;
    flex-direction: row;
    gap: 17px;
    width: 1512px;
}

.intro-block-text > p {
    text-align: start !important;
}

.homepage-main .be-a-better-leader-build-a-better-world {
    color: var(--congress-blue);
    font-family: var(--font-family-reckless_neue-regular);
    /* font-size: 80px; */
    font-size:64px;
    font-weight: 400;
    letter-spacing: 0;
    /* line-height: 90px; */
    line-height: 72px;
    margin-left: 1.0px;
    margin-top: 28px;
    min-height: unset;
    text-align: center;
    width: 1071px;
}

.homepage-main .lorem-ipsum-dolor-si-1 {
    letter-spacing: -0.44px;
    line-height: 29px;
    margin-left: 0;
    min-height: auto;
    text-align: center;
    margin-bottom: 15px;
    width: auto!important;
}
@media screen and (max-width: 768px) {
    .homepage-main .lorem-ipsum-dolor-si-1 {
        width: auto!important;
    }
}

.homepage-main .intro-video {
    background-color: #d0d9df;
    border-radius: 10.78px;
    display: flex;
    overflow: hidden;
    width: 1250px;
}

.homepage-main .overlap-group-1 {
    background-image: url(../../img/matteo-vistocco-dph00r2swfo-unsplash-1-1.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 358px;
    margin-top: 0;
    position: relative;
    width: 1250px;
}

.homepage-main .mitchell-luo-h3ht-k8 {
    height: 704px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1250px;
}

.homepage-main .prince-akachi-i2ho-d {
    height: 358px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 1250px;
}

.homepage-main .play {
    height: 50px;
    left: 555px;
    position: absolute;
    top: 156x;
    width: 50px;
}

.homepage-main .ellipse-21 {
    border: 2.16px solid;
    border-color: #fff2f5;
    border-radius: 103.48px;
    height: 105px;
    left: 522px;
    position: absolute;
    top: 248px;
    width: 105px;
}

.homepage-main .overlap-group4 {
    height: 2501px;
    position: relative;
    width: 100%;
}

.qoute-block{
    width: 100% !important;
}

.homepage-main .footer {
    align-items: flex-start;
    background-color: var(--cobalt);
    display: flex;
    flex-direction: row;
    gap: 144px;
    height: 786px;
    justify-content: flex-start;
    left: 0;
    mix-blend-mode: normal;
    padding: 103.5px 80px;
    position: absolute;
    top: 1715px;
    width: 1512px;
}

.homepage-main .flex-col-1 {
    align-items: center;
    margin-left: 41px;
    margin-top: 8.48px;
    min-height: 545px;
    width: 369px;
}

.homepage-main .group-7 {
    height: 297px;
    margin-left: 21.0px;
    width: 302px;
}

.homepage-main .university-logos {
    align-items: flex-start;
    display: flex;
    gap: 46px;
    justify-content: flex-end;
    margin-top: 9px;
    min-width: 369px;
}

.homepage-main .legatum_-primary_-logo-1 {
    height: 73px;
    top: 0;
}

.homepage-main .legatum_-primary_-logo-2 {
    height: 19px;
    top: 70px;
}

.homepage-main .harvard {
    align-self: flex-end;
    height: 100px;
    margin-bottom: -0.37px;
    width: 85px;
}

.homepage-main .oxford-logo {
    align-items: flex-start;
    align-self: center;
    background-color: var(--magic-potion);
    display: flex;
    height: 85px;
    margin-bottom: 1.0px;
    min-width: auto;
    padding: 6.8px 7.3px;
}

.homepage-main .group_2749 {
    height: 70px;
    width: 70px;
}

.homepage-main .flex-col-2 {
    align-items: flex-start;
    align-self: flex-end;
    min-height: 534px;
    width: 798px;
}

.homepage-main .email-input {
    align-items: center;
    display: flex;
    gap: 12px;
    height: 50px;
    left: 401px;
    min-width: 380px;
    position: absolute;
    top: 1px;
}

.homepage-main .button {
    align-items: flex-start;
    border: 1px solid;
    border-color: var(--white);
    border-radius: 50px;
    display: flex;
    height: 50px;
    margin-left: -16px;
    overflow: hidden;
    padding: 7px 18px;
    width: 307px;
}

.homepage-main .button-1 {
    align-items: center;
    background-color: var(--white);
    height: 46px;
    padding: 0 11px;
    width: 46px;
}

.homepage-main .text-13 {
    letter-spacing: -0.52px;
    line-height: normal;
    min-height: 32px;
    text-align: center;
    width: 23px;
}

.homepage-main .overlap-group1-2 {
    height: 111px;
    left: 0;
    position: absolute;
    top: 0;
    width: 749px;
}

.homepage-main .sign-up-to-our-newsletter {
    left: 0;
    letter-spacing: 0;
    line-height: 40px;
    position: absolute;
    top: 0;
    width: 385px;
}

.homepage-main .line-9 {
    height: 1px;
    left: 5px;
    object-fit: cover;
    position: absolute;
    top: 101px;
    width: 744px;
}

.homepage-main .page-links {
    align-items: flex-start;
    display: flex;
    height: 259px;
    margin-left: 4px;
    margin-top: 42px;
    min-width: 794px;
}

.homepage-main .flex-col {
    flex-direction: column;
    margin-top: -1px;
    min-height: 259px;
    width: 246px;
}

.homepage-main .about {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 42px;
    min-height: 33px;
    white-space: nowrap;
}

.homepage-main .brought-to-you-by {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    min-height: 33px;
    white-space: nowrap;
}

.homepage-main .harvard-1 {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 42px;
    min-height: 33px;
    white-space: nowrap;
}

.homepage-main .legatum {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 42px;
    min-height: 33px;
    white-space: nowrap;
}

.homepage-main .flex-col-3 {
    align-items: flex-start;
    margin-left: 56px;
    margin-top: -1px;
    min-height: 259px;
    width: 246px;
}

.homepage-main .place {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 43px;
    min-height: 33px;
    white-space: nowrap;
}

.homepage-main .flex-row {
    gap: 74px;
    height: 35px;
    margin-left: 5px;
    margin-top: 87px;
    min-width: 744px;
}

.homepage-main .socials {
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    gap: 13px;
    min-width: 110px;
}

.homepage-main .vector {
    align-self: center;
    height: 20px;
    margin-top: 0.72px;
    width: 24px;
}

.homepage-main .iconlinkedin {
    height: 29px;
    margin-top: 0;
    width: 30px;
}

.homepage-main .iconyoutube {
    height: 29px;
    margin-top: 0;
    width: 29px;
}

.homepage-main .explore-courses {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 186px;
    min-height: 1345px;
    position: absolute;
    top: 460px;
    width: 100%;
}

 .homepage-main .explore-the-courses {
    color: var(--cobalt-sec);
    font-family: var(--font-family-reckless_neue-regular);
     font-size: 80px; 
     font-size:64px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 90px;
    margin-left: 2px;
    margin-top: 100px;
    min-height: unset;
    white-space: nowrap; 
}

.journey-container .lorem-ipsum-dolor-si-1 {
    text-align: start;
}

.learning-journey-image{
    margin-top: 100px;
    display: flex;
    justify-content: center;
    background-color : #FFF6F6
}

.journey-container {
display: flex;
flex-direction: row;
gap: 100px;

}



.learning-journey {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.explore-courses .lorem-ipsum-dolor-si-1{
    margin-top: 0px;
    margin-bottom: 25px;
    text-align: start;
}

.legatum-divider{
    width: 100%;
    border: 1px solid #0A3887;
    margin-top: 25px;
    margin-bottom: 25px;
}

/* .homepage-main .courses {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 65px;
    padding: 0 2px;
} */

.homepage-main .course-container {
    align-items: flex-end;
    display: flex;
    gap: 24px;
    height: 696px;
    margin-top: -150px;
    min-width: 1499px;
}

.homepage-main .course_01 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-height: 696px;
    width: 738px;
}

.homepage-main .x03-thumbnail {
    align-items: flex-start;
    background-color: var(--geyser);
    border-radius: 11.24px;
    display: flex;
    overflow: hidden;
    padding: 0 0.0px;
    width: 743px;
}

.homepage-main .unsplash-container-1 {
    height: 418px;
    position: relative;
    width: 743px;
}

.homepage-main .inbal-marilli-lg-ui {
    height: 418px;
    left: 342px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 401px;
}

.homepage-main .chris-blonk-bn5cob0k9g-e-unsplash-1 {
    height: 418px;
    left: 0;
    position: absolute;
    top: 0;
    width: 370px;
}

.homepage-main .chris-blonk-bn5cob0k {
    height: 418px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 370px;
}

.homepage-main .x01-why-lead {
    color: var(--midnight-blue);
    font-family: var(--font-family-neue_haas_grotesk_display_pro-45light);
    font-size: var(--font-size-l);
    font-weight: 300;
    letter-spacing: 0;
    line-height: 45px;
    margin-left: 9px;
    margin-top: 18px;
    min-height: 48px;
    white-space: nowrap;
}

.homepage-main .lorem-ipsum-dolor-si {
    align-self: flex-end;
    color: var(--midnight-blue);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xxs);
    font-weight: 300;
    letter-spacing: -0.44px;
    line-height: 29px;
    margin-top: 19px;
    min-height: 104px;
    width: 727px;
}

.homepage-main .button-2 {
    align-items: flex-start;
    background-color: var(--midnight-blue);
    cursor: pointer;
    height: 50px;
    margin-left: 11px;
    margin-top: 39px;
    padding: 7px 21px;
    width: 184px;
}

.homepage-main .get-started {
    letter-spacing: -0.52px;
    line-height: normal;
    min-height: 32px;
    text-align: center;
    width: 141px;
}

.homepage-main .thumbnail_02 {
    align-items: flex-start;
    align-self: flex-end;
    background-color: var(--geyser);
    border-radius: 11.18px;
    display: flex;
    margin-right: -14.42px;
    margin-top: -4px;
    min-width: 741px;
    padding: 0 0.0px;
}

.homepage-main .prince-akachi-l-wk-fhe-gple-e-unsplash-1 {
    height: 416px;
    width: 370px;
}

.homepage-main .dominika-hesounova-x {
    height: 416px;
    width: 240px;
}

.homepage-main .x02-leading-self-with-courage {
    color: var(--midnight-blue);
    font-family: var(--font-family-neue_haas_grotesk_display_pro-45light);
    font-size: var(--font-size-l);
    font-weight: 300;
    letter-spacing: 0;
    line-height: 45px;
    margin-left: 7px;
    margin-top: 19px;
    min-height: 48px;
    white-space: nowrap;
}

.homepage-main .button-3 {
    align-items: flex-start;
    border: 2px solid;
    border-color: var(--midnight-blue);
    height: 50px;
    margin-left: 10px;
    margin-top: 39px;
    padding: 6px 21px;
    width: 184px;
}

.homepage-main .learn-more {
    color: var(--midnight-blue);
    font-family: var(--font-family-instrument_sans);
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -0.52px;
    line-height: normal;
    min-height: 32px;
    text-align: center;
    width: 141px;
}

/* .homepage-main .courses-slider-control {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 18px;
    min-height: 29px;
    width: 1334px;
} */

.homepage-main .overlap-group-2 {
    height: 4px;
    position: relative;
    width: 1334px;
}

.homepage-main .line-5 {
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1334px;
}

.homepage-main .rectangle-7 {
    background-color: var(--midnight-blue);
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 360px;
}

.homepage-main .arrow {
    height: 15px;
    width: 20px;
}

.homepage-main .qoute-block {
    align-items: flex-end;
    background-color: var(--blue-chalk);
    display: flex;
    flex-direction: column;
    gap: 7px;
    height: 476px;
    left: 0;
    padding: 124px 245px;
    position: absolute;
    top: 0;
    width: 1512px;
}

.homepage-main .flex-row-1 {
    align-items: flex-start;
    align-self: center;
    display: flex;
    gap: 73px;
    height: 167px;
    margin-left: 2.0px;
    min-width: 998px;
}

.homepage-main .a-leader-is-one-who {
    align-self: flex-end;
    letter-spacing: 0;
    line-height: 43px;
    min-height: 108px;
    width: 756px;
}

.homepage-main .image-10 {
    height: 166px;
    object-fit: cover;
    width: 169px;
}

.homepage-main .john-maxwell {
    color: var(--midnight-blue);
    font-family: var(--font-family-instrument_serif);
    font-size: 34px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 43px;
    margin-bottom: -2px;
    min-height: 52px;
    text-align: center;
    white-space: nowrap;
    width: 192px;
}

.homepage-main .button-4 {
    border-radius: 50px;
    display: flex;
    overflow: hidden;
}

.homepage-main .flex-col-4 {
    display: flex;
    flex-direction: column;
}

.homepage-main .navbar-link {
    letter-spacing: -0.45px;
    line-height: normal;
    margin-bottom: 3.0px;
    min-height: 28px;
}


.rectangle-21.rectangle{
    background-color: #04122a33;
    height: 80vh;
    mix-blend-mode: darken;
    top: 139px;
    transform: rotate(180.00deg);
    width: 1622px;
}

.grad-2 {
    background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
    left: 52px;
    position: absolute;
    width: 1512px;
}

.grad {
    height: 352px;
    top: 0;
}

.grad-1 {
    height: 356px;
    mix-blend-mode: overlay;
    opacity: 0.1;
    top: 816px;
    transform: rotate(180.00deg);
}

.italic{
    font-style: italic;
    font-family: "Instrument Serif", Helvetica;
}

.homepage-main.screen{
    width: 100%;
}

.homepage{
    width: 100%;
}

.overlap-group3{
    width: 100% !important;
}


.homepage-main .hero-image{
    position: static !important;
    top:auto;
    left:auto;
    transform: none;
    width: 100%;
}

.homepage-main .overlap-group2{
    /* transform: rotate(90deg); */
}

.homepage-main .overlap-group2{
    width: 100%;
}

.homepage-main .full-bleed-image{
    transform:none;
}

.homepage-main .full-bleed-image{
    width: 100%;
    left:0 !important
}

.homepage-main .unsplash-container-item{
    transform: none;
    bottom:0;
}

.hero-image-1{
    width: 100%;
}

.overlap-group2{
    width: 100%;
}

.hero-image-1{
    width: 100% !important;
}

.overlap-group1 {

    width: 100% !important;
}

.full-bleed-image-1{
    transform: none !important;
    position: relative !important;
    top:0 !important;
    bottom:0 !important;
    left:0 !important;
    right:0 !important;
    width: 100% !important;
}

.homepage-main .unsplash-container{
    width: 100%;
}

.unsplash-container-item {
    width: 100% !important;
}

.intro-block{
    width: 100%   !important;
}

.footer{
    width: 100%  !important;
}


.hero-image-1 .graphic{
    max-width: 90%;
}

/*main.css*/

.homepage-main  .course-item p {
    margin-bottom: 0;
}
.carousel-course-item{
    padding: 0 10px !important;
    max-width: 500px !important;
}

.course-item p {
    letter-spacing: -0.44px;
    line-height: 29px;
    margin-top: 10px;
    color: #0a3887;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 20px;
    overflow: hidden;
    max-height: 110px;
    height: 110px;
    text-overflow: ellipsis;
}

.fixed-height {
    max-height: 110px;
    height: 110px;
}

 .intro-block-custom {
    padding: 60px 0px;
  }

  .homepageContainer {
    
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .purple-home{
    background-color: var(--snuff);
    width: 100%;

  }

  .legatum-divider-container{
    width: 100%;
  }


  .home-intro-text{
    
     font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    font-family: var( --font-family-reckless_trial-light);
    color : white;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 100%;
  }
.home-intro-text span{
    font-style: italic;
}

  .quote-position {
    font-size: 15px;
    font-style: italic;
    line-height: 24px;
    font-weight: 300;
    font-family: var(--font-family-inter);
    text-align: start;
    width: 157px; 
  }





@media (max-width: 940px)  {
    .quote-text {
        text-align: center;
    }
}


/* media queries  */
@media (min-width: 320px) and (max-width: 768px) {
  /* Styles for devices from phone to tablet */
    .homepage-main .intro-block {
    flex-direction: column;


}




.legatum-divider-container{
    padding-left: 4%;
    padding-right: 4%;
}

.homepage-main .intro-video {
    margin : 20px 0px;
}


  .intro-block-custom {
    padding: 20px;
  }

    .homepage-main .explore-the-courses{
    margin-top: 40px;
  }

  .course-container .react-multiple-carousel__arrow {

  width: 20px !important;
  height: 20px !important;
  top: 45% !important;
  border-radius: 50%;
}

.homepage-main .courses {
    margin-bottom: 50px;
  
}

.journey-container {
 
    flex-direction: column-reverse;
    gap: 0px;
    padding-left: 4%;
    padding-right: 4%;
}

.journey-container  .started {
    margin-top: 0px !important;
    min-height: 80px !important;

    
}

.journey-container  .end {
    margin-top: 0px !important;
    min-height: 110px !important;
    
}



.learning-journey-image {
    margin-top: 50px;
    
}


  
}


@media (min-width: 768px)  {
  /* Styles for devices from tablet to desktop */
  .homepage-main .intro-block {
    flex-direction: row;
    gap: 17px;
    width: 100%;
   
  }

  
}


@media (max-width: 745px)  {
  /* Styles for devices from tablet to desktop */
 .home-intro-text{
    font-size: 14px;
    line-height: 15px;
     padding: 0 20px;
 }

  
}


@media (min-width: 769px) and (max-width: 1281px) {
  /* Styles for devices from tablet to desktop */
  
.legatum-divider-container{
    padding-left: 3%;
    padding-right: 3%;
}

.journey-container {
 
    flex-direction: column-reverse;
    gap: 0px;
    padding-left: 4%;
    padding-right: 4%;
}

.journey-container  .started {
    margin-top: 0px !important;
    min-height: 100px !important;

    
}

.journey-container  .end {
    margin-top: 0px !important;
    min-height: 80px !important;
    
}

 .intro-block-custom{
    padding-left: 3%;
    padding-right: 3%;
   }



}
.testimonial-custom-gap{
    gap: 0px;
}
.testimonial-custom-gap .quote-position{
    width: auto;
    text-align: center;
}

.journey-container-text-block{
    gap: 10%;
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin-bottom: 25px;
}
@media screen and (max-width: 786px) {
    .journey-container-text-block{
        display: block;
    }
}


@media screen and (min-width: 991px) {
    .homepage-main .intro-video{
        min-width: 600px!important;
    }
}




        