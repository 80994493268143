.legatum-header-logo{
    margin-bottom: 6px
}

@media(max-width : 791px){
    .legatum-header-logo{
        width: 70%;
    }
}
.header-submenu-overlay{
    width: -webkit-fill-available;
    background: #FFF;
    height: 33px;
    z-index: 99999;
    position: absolute;
    top:86px;
}
body:has(.nav.scrolled) .header-submenu-overlay{
    display: none;
}
.nav:not(.scrolled) .drop-down.active a{
    color: var(--cobalt-sec)!important;
}
.nav .drop-down a{
    font-size: 16px!important;
}
.nav:not(.scrolled) .drop-down.active a:hover{
    text-decoration: underline!important;
}
.nav:not(.scrolled) .drop-down{
    width: max-content;
    padding-top: 40px;
}
/*.navbar-link.logged-in{
    margin-top: -16px;
}*/
.not-logged-in-user-block{
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ECD8FB;
}
.not-logged-in-user-block img{
    opacity: .5;
}
.not-logged-in-user-container{
    padding: 2px;
    border: solid .6px #fff;
    border-radius: 50%;
}
.drop-down.contact-dd.user-menu-dd{
    margin-top: 16px!important;
}
@media screen and (max-width: 991px) {
    .menu-drop{
        position: static!important;
    }
    .mobile_menu .navbar-link.w-dropdown{

        justify-content: flex-end!important;
    }
    .navbar-link.w-dropdown:hover > .drop-down:not(.active){
        display: none!important;
    }
    .mobile_menu .navbar-link.w-dropdown:hover > .drop-down.active, .navbar-link.w-dropdown > .drop-down.active{
        /*display: block;*/
        z-index: 999 !important;
        border-radius: 5px !important;
        padding: 10px 5px 5px 10px !important;
        left: -33px !important;
        top: 33px !important;
        background-color: #ffffff !important;
        position: absolute !important;
        height: auto!important;
        margin-left: 10px!important;;
        transform: none!important;;
        margin-top: 0px!important;;
    }
    .mobile_menu .navbar-link.w-dropdown{
        margin-left: auto !important;
    }
    .navbar-link.w-dropdown > .drop-down:not(.active) .about-drop{
        display: none!important;
    }
    .nav .mobile_menu.active .drop-down a{
        padding-left: 5px;
        padding-right: 15px!important;
        width: auto!important;
    }
    .nav .mobile_menu.active .overlap-group1-1.navbar-link.w-dropdown{
        padding-right: 116px!important;
        height: auto!important;
    }
    .mobile_menu .user-menu-dd .about-drop{
        text-align: left!important;;
    }
    .nav.mobile-nav-custom .mobile_menu .user-menu-dd .about-drop{
        padding-right: unset!important;
    }
    .drop-down.contact-dd.user-menu-dd{
        width: auto!important;
    }
    .nav.mobile-nav-custom .mobile_menu .navbar-link.w-dropdown:hover .drop-down.contact-dd.user-menu-dd{
        right: unset!important;
        margin-top: 10px!important;
    }
    .about .overlap-group1-1{
        width: auto!important;
    }
    body .homepage-main .overlap-group1-1, .course-overview .overlap-group1-1{
        position: relative!important;
        width: 33px!important;
    }
}

@media screen and (min-width: 991px){
    .screen.user-dashboard .nav{
        top: 45px!important;
    }
    .nav.scrolled .drop-down.active, .screen.user-dashboard .nav .drop-down.active{
        width: max-content;
        position: absolute;
        transform: unset;
        margin-top: unset;
        padding-top: 70px;
        display: flex;
        justify-content: unset;
        inset: 10px auto auto -19px !important;
    }

    .nav.scrolled .about-drop, .screen.user-dashboard .nav .about-drop{
        flex-direction: column;
        gap: 5px!important;
        background: white;
        color: var(--cobalt);
        border-radius: 5px;
        padding: 5px 10px 10px 10px;
        box-shadow: 0 2px 2px 2px #0003;
    }
    .nav.scrolled .about-drop span{
        cursor: pointer!important;
    }
    .user-dashboard .nav{
        position: fixed!important;
        background-color: var(--cobalt) !important;
    }
}