.achievement-container .badge_-container{
    min-height: unset!important;
    display: flex!important;
}
.achievement-container .my-achievements{
    padding-bottom: unset!important;
    max-height: unset!important;
    overflow: unset!important;
}
.achievement-container .overlap-group6{
    padding: 10px 30px!important;
    overflow: auto;
    max-width: 600px;
}
.achievement-container .badge_-container .cred-box img{
    margin-bottom: 9px;
    max-height: 80px;
}
.achievement-text-helper{
    width: 100%;
    text-align: center;
    margin: 15px 0px;
    font-size: 12px;
    color: var(--cobalt);
}
.my-achievements .achievements{
    margin-bottom: 20px;
}