/* screen - profile-guide */

.profile-guide {
    align-items: flex-start;
    background-color: var(--white);
    border: 1px none;
    display: flex;
    flex-direction: column;
    height: 3437px;
    width: 1512px;
}

.profile-guide .overlap-group5 {
    height: 539px;
    position: relative;
    width: 1512px;
}

.profile-guide .hero-image {
    align-items: flex-end;
    background-color: var(--denim);
    display: flex;
    flex-direction: column;
    height: 1512px;
    left: 325px;
    overflow: hidden;
    padding: 89.5px 0;
    position: absolute;
    top: -546px;
    transform: rotate(90deg);
    width: 862px;
}

.profile-guide .graphic_r {
    height: 162px;
    margin-right: -114.0px;
    margin-top: 25px;
    transform: rotate(-90deg);
    width: 390px;
}

.profile-guide .guide-mike-smith {
    letter-spacing: 0;
    line-height: 88px;
    margin-right: -9.5px;
    margin-top: 367px;
    min-height: 222px;
    text-align: center;
    transform: rotate(-90deg);
    width: 639px;
}

.profile-guide .graphic_l {
    height: 162px;
    margin-right: -89.5px;
    margin-top: 395px;
    transform: rotate(-90deg);
    width: 341px;
}

.profile-guide .nav {
    align-items: flex-start;
    display: flex;
    gap: 528px;
    height: 95px;
    left: 0;
    padding: 1px 64px;
    position: absolute;
    top: 40px;
    width: 1512px;
}


.profile-guide .navbar-link-about {
    min-width: 64px;
}

.profile-guide .navbar-link-place {
    min-width: 82px;
}

.profile-guide .navbar-link-login {
    min-width: 57px;
}

.profile-guide .user-icon {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    height: 37px;
    min-width: 37px;
}

.profile-guide .overlap-group1 {
    background-color: var(--snuff);
    border-radius: 18px;
    height: 36px;
    position: relative;
    width: 36px;
}

.profile-guide .ellipse-container {
    height: 19px;
    left: 9px;
    position: absolute;
    top: 8px;
    width: 16px;
}

.profile-guide .ellipse-11 {
    border: 1.32px solid;
    border-color: #fff;
    border-radius: 6.17px;
    height: 12px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 12px;
}

.profile-guide .ellipse-12 {
    border: 1.32px solid;
    border-color: #fff;
    border-radius: 16.55px;
    height: 33px;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 33px;
}

.profile-guide .page-nav {
    flex-direction: column;
    gap: 5px;
    margin-left: 128px;
    margin-top: 137px;
    min-height: 35px;
    padding: 1px 0;
    width: 350px;
}

.profile-guide .about-presented-by-mike-smith {
    letter-spacing: 0;
    line-height: 24.3px;
    margin-top: 3px;
    white-space: nowrap;
}

.profile-guide .line-container {
    align-items: flex-start;
    display: flex;
    gap: 19px;
    margin-left: 2px;
    min-width: 206px;
}

.profile-guide .line-6 {
    height: 1px;
    object-fit: cover;
    width: 63px;
}

.profile-guide .line-7 {
    height: 1px;
    object-fit: cover;
    width: 124px;
}

.profile-guide .mentor-bio {
    align-items: flex-end;
    align-self: center;
    display: flex;
    gap: 143px;
    margin-left: 89.0px;
    margin-top: 104px;
    min-width: 1259px;
}

.profile-guide .headshot {
    align-items: flex-start;
    background-color: var(--ebb);
    border-radius: 500px;
    display: flex;
    margin-left: -42px;
    overflow: hidden;
    width: 372px;
}

.profile-guide .chris-blonk-bn5cob0k {
    height: 372px;
    object-fit: cover;
    width: 372px;
}

.profile-guide .overlap-group {
    height: 732px;
    margin-bottom: -378px;
    position: relative;
    width: 752px;
}

.profile-guide .lorem-ipsum-dolor-si {
    left: 0;
    letter-spacing: 0.48px;
    line-height: 29px;
    position: absolute;
    margin-top: 138px;
    width: 752px;
}

.profile-guide .guide-bio {
    color: var(--congress-blue);
    font-family: var(--font-family-instrument_serif);
    font-size: var(--font-size-xl);
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 54px;
    position: absolute;
    top: 0;
    width: 551px;
}

.profile-guide .span1 {
    font-family: var(--font-family-reckless_neue-regular);
}



.profile-guide .email {
    height: 24px;
    position: relative;
    width: 33px;
}

.profile-guide .twitter {
    height: 28px;
    position: relative;
    width: 34px;
}

.profile-guide .linkedin {
    height: 41px;
    position: relative;
    width: 42px;
}

.profile-guide .youtube {
    height: 41px;
    position: relative;
    width: 41px;
}

.profile-guide .as-seen-on {
    align-items: flex-start;
    background-color: var(--blue-chalk);
    display: flex;
    flex-direction: column;
    gap: 59px;
    margin-top: 440px;
    min-height: 1024px;
    padding: 79px 126px;
    width: 1512px;
}

.profile-guide .providing-insighton-these-courses {
    letter-spacing: 0;
    line-height: 54px;
    margin-left: 2px;
    min-height: 108px;
    width: 386px;
}

.profile-guide .overlap-group-container {
    align-items: flex-start;
    display: flex;
    gap: 22px;
    min-width: 1258px;
}

.profile-guide .t-container {
    height: 548px;
    position: relative;
    width: 621px;
}

.profile-guide .x01-text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 0;
    min-height: 186px;
    position: absolute;
    top: 362px;
    width: 621px;
}

.profile-guide .x-lead {
    letter-spacing: 0;
    line-height: 45px;
    margin-top: 23px;
    min-height: 48px;
    white-space: nowrap;
}

.profile-guide .lorem-ipsum-dolor-si-1 {
    margin-left: 1px;
    width: 620px;
}

.profile-guide .button {
    align-items: center;
    align-self: flex-end;
    background-color: var(--congress-blue);
    border-radius: 50px;
    display: flex;
    height: 46px;
    margin-right: 33px;
    margin-top: 35px;
    overflow: hidden;
    padding: 0 11px;
    width: 46px;
}

.profile-guide .x03-thumbnail {
    align-items: flex-start;
    background-color: var(--geyser);
    border-radius: 10px;
    display: flex;
    height: 372px;
    left: 2px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 619px;
}

.profile-guide .chris-blonk-bn5cob0k-1 {
    height: 372px;
    object-fit: cover;
    width: 304px;
}

.profile-guide .inbal-marilli-lg-ui {
    height: 372px;
    object-fit: cover;
    width: 315px;
}

.profile-guide .x-t-container {
    height: 548px;
    position: relative;
    width: 615px;
}

.profile-guide .x04-text {
    flex-direction: column;
    min-height: 186px;
    top: 362px;
}

.profile-guide .lorem-ipsum-dolor-si-2 {
    width: 615px;
}

.profile-guide .button-1 {
    align-items: center;
    align-self: flex-end;
    background-color: var(--congress-blue);
    height: 46px;
    margin-right: 28px;
    margin-top: 35px;
    padding: 0 11px;
    width: 46px;
}

.profile-guide .x04-thumbnail {
    background-color: var(--geyser);
    border-radius: 10px;
    height: 372px;
    overflow: hidden;
    top: 0;
}

.profile-guide .footer {
    align-items: flex-start;
    background-color: var(--cobalt);
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    gap: 144px;
    height: 786px;
    justify-content: flex-start;
    mix-blend-mode: normal;
    padding: 103.5px 80px;
    width: 1512px;
}

.profile-guide .flex-col-1 {
    align-items: center;
    margin-left: 41px;
    margin-top: 8.48px;
    min-height: 545px;
    width: 369px;
}

.profile-guide .group-7 {
    height: 297px;
    margin-left: 21.0px;
    width: 302px;
}

.profile-guide .university-logos {
    align-items: flex-start;
    display: flex;
    gap: 46px;
    justify-content: flex-end;
    margin-top: 9px;
    min-width: 369px;
}

.profile-guide .legatum_-primary_-logo-1 {
    height: 73px;
    top: 0;
}

.profile-guide .legatum_-primary_-logo-2 {
    height: 19px;
    top: 70px;
}

.profile-guide .harvard {
    align-self: flex-end;
    height: 100px;
    margin-bottom: -0.37px;
    width: 85px;
}

.profile-guide .oxford-logo {
    align-items: flex-start;
    align-self: center;
    background-color: var(--magic-potion);
    display: flex;
    height: 85px;
    margin-bottom: 1.0px;
    min-width: 85px;
    padding: 6.8px 7.3px;
}

.profile-guide .group_2749 {
    height: 70px;
    width: 70px;
}

.profile-guide .flex-col-2 {
    align-items: flex-start;
    align-self: flex-end;
    min-height: 534px;
    width: 798px;
}

.profile-guide .overlap-group2 {
    height: 111px;
    position: relative;
    width: 781px;
}

.profile-guide .email-input {
    align-items: center;
    display: flex;
    gap: 12px;
    height: 50px;
    left: 401px;
    min-width: 380px;
    position: absolute;
    top: 1px;
}

.profile-guide .button-2 {
    align-items: flex-start;
    border: 1px solid;
    border-color: var(--white);
    height: 50px;
    margin-left: -16px;
    padding: 7px 18px;
    width: 307px;
}

.profile-guide .button-3 {
    align-items: center;
    background-color: var(--white);
    height: 46px;
    padding: 0 11px;
    width: 46px;
}

.profile-guide .overlap-group1-1 {
    height: 111px;
    left: 0;
    position: absolute;
    top: 0;
    width: 749px;
}

.profile-guide .sign-up-to-our-newsletter {
    left: 0;
    letter-spacing: 0;
    line-height: 40px;
    position: absolute;
    top: 0;
    width: 385px;
}

.profile-guide .line-9 {
    height: 1px;
    left: 5px;
    object-fit: cover;
    position: absolute;
    top: 101px;
    width: 744px;
}

.profile-guide .page-links {
    height: 259px;
    margin-left: 4px;
    margin-top: 42px;
    min-width: 794px;
}

.profile-guide .flex-col {
    flex-direction: column;
    margin-top: -1px;
    min-height: 259px;
    width: 246px;
}

.profile-guide .about {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 42px;
    min-height: 33px;
    white-space: nowrap;
}

.profile-guide .brought-to-you-by {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    min-height: 33px;
    white-space: nowrap;
}

.profile-guide .harvard-1 {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 42px;
    min-height: 33px;
    white-space: nowrap;
}

.profile-guide .legatum {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 42px;
    min-height: 33px;
    white-space: nowrap;
}


.profile-guide .place {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 43px;
    min-height: 33px;
    white-space: nowrap;
}

.profile-guide .flex-row {
    gap: 74px;
    height: 35px;
    margin-left: 5px;
    margin-top: 87px;
    min-width: 744px;
}

.profile-guide .socials-1 {
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    gap: 13px;
    min-width: 110px;
}

.profile-guide .vector {
    align-self: center;
    height: 20px;
    margin-top: 0.72px;
    width: 24px;
}

.profile-guide .iconlinkedin {
    height: 29px;
    margin-top: 0;
    width: 30px;
}

.profile-guide .iconyoutube {
    height: 29px;
    margin-top: 0;
    width: 29px;
}

.profile-guide .button-4 {
    border-radius: 50px;
    display: flex;
    overflow: hidden;
}


.profile-guide .lorem-ipsum-dolor-si-3 {
    letter-spacing: -0.44px;
    line-height: 29px;
    margin-top: 15px;
    min-height: 104px;
}

.profile-guide .navbar-link {
    cursor: pointer;
    letter-spacing: -0.45px;
    line-height: normal;
    margin-bottom: 3.0px;
    min-height: 28px;
}

.profile-guide .page {
    align-items: flex-start;
    display: flex;
}

.profile-guide .x04 {
    align-items: flex-start;
    display: flex;
    left: 0;
    position: absolute;
    width: 615px;
}


.profile-guide .page{
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.profile-guide .mentor-bio {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-width: auto  !important;
}

.profile-guide .overlap-group{
    height: auto
}

.profile-guide .overlap-group{
    position: static;
}

.profile-guide .lorem-ipsum-dolor-si{
    position: static;
}

.profile-guide .guide-bio{
    position: static;
}

.profile-guide .socials{
    position: static !important;
}

.dflex{
    display: flex;
}

.dflex .socials{
    width: 100%;
    justify-content: flex-end;
    display: flex;
    gap:10px;
    align-items: center;
}

.profile-guide .lorem-ipsum-dolor-si{
    margin-top: 40px;
}

.profile-guide .mentor-bio{
    align-items: flex-start;
}

.socials a[data-name="email"]{
    display: none;
}

.profile-guide .overlap-group-container{
    min-width: auto !important;
}

.courses-slider .course-item{
    max-width: unset !important;
}

.profile-guide .overlap-group-container{
    width: 100% !important;
}


/*main.css*/

* {
    box-sizing: border-box !important;
}
.footer {
    align-items: flex-start;
    background-color: var(--cobalt);
    display: flex;
    flex-direction: row;
    gap: 100px !important;
    height: 786px;
    justify-content: flex-start;
    left: 0;
    mix-blend-mode: multiply;
    padding: 103.5px 80px;
    position: static !important;
    width: 100% !important;
}

.nav {
    /* align-items: flex-start; */
    display: flex;
    gap: 528px;
    height: 95px;
    left: 0;
    padding: 1px 64px;
    position: absolute;
    top: 41px;
}

.nav{
    width: 100% !important;
    margin-left : auto !important;
    margin-right : auto !important;
    justify-content: center; /* Center horizontally */
    gap: 0 !important;
    height: 95px;
    left: 50% !important;  /* Position from the horizontal center */
    top: 70px !important;  /* Position from the bottom */
    position: absolute;
    transform: translate(-50%, -50%); /* Center the element */
    width: 1512px;
    justify-content: space-between !important;
    z-index: 2222;
    flex-wrap: wrap;
    padding:0 !important;

}

.navbar-link {
    letter-spacing: -0.45px;
    line-height: normal;
    margin-bottom: 3px;
    min-height: 28px;
}

.navbar-link {
    color: white;
}

.navbar {
    align-items: center;
    align-self: center;
    display: flex;
    height: 37px;
    margin-bottom: 16px;
    min-width: 564px;
    padding: 1.3px 0;
}

.navbar{
    justify-content: end;
    min-width: auto !important;
}


.footer{
    position: relative;
    padding-left: 10vw  !important;
    padding-right: 10vw  !important;


}

.a-joint-initiative-o{
    width: auto !important;
}

.overlap-group3{
    width: 100vw !important;
}

.navbar-link, .overlap-group1-1{
    margin-left: 2vw !important;
    min-width: auto !important;
}

.be-a-better-leader-build-a-better-world{
    width: auto !important;
}

.lorem-ipsum-dolor-si-1{
    width: auto !important;
}


.intro-video, .overlap-group-1, .prince-akachi-i2ho-d, .mitchell-luo-h3ht-k8{
    max-width: 1250px !important;
    width: 100% !important;
}

.ellipse-21 {
    position: static !important;
    z-index: 2222;
}



.overlap-group-1{
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.flex-col-1 {
    width: auto !important;
    margin-left: 0 !important;
}

.group-7, .a-joint-initiative-of{
    width: auto !important;
}

.university-logos {
    min-width: auto !important;
}

.overlap-group1-2{
    width: auto !important;
}

.overlap-group2-1{
    width: auto !important;
}

.flex-col-2 {
    width: auto !important;
}

.page-links{
    min-width: auto !important;
}

.flex-col{
    width: auto !important;
}

.flex-row{
    width: auto !important;
    min-width: auto !important;
}

.copyright-2023-leg{
    width: auto !important;
}

.footer .flex-row{
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 87px !important;
}

.footer .flex-col-1.flex-col-4{
    width: 26% !important;
    flex-grow: 1;
    max-width: 289px;
}

.footer .flex-col-2.flex-col-4{
    flex-grow: 1 !important;
}
.flex-col-3 {
    width: auto !important;
}

@media screen and (max-width: 1424px) {
    .courses-grid{
        padding-left: 3% !important;
        padding-right: 3% !important;
    }
}


@media screen and (max-width: 1281px) {
    .progress-div .page-nav .nav-cont{
        margin-left: 0 !important;
    }
    .nav, .course-overview .overlap-group9, .user-dashboard .flex-row ,  .working-main, .course-error-message, .brought-row, .tutor-list{
        padding-left: 3% !important;
        padding-right: 3% !important;
    }

    .user-dashboard .courses-in-progress{
        margin-left: 3%;
        margin-right: 3%;
    }

    .profile-guide .mentor-bio{
        padding-left: 3%;
        padding-right: 3%;
    }

    .tutors, .course-progress .progress-bar,  .course-overview .course-overview-text{
        /* margin-left: 0% !important;
        margin-right: 0% !important; */
        padding-left: 3% !important;
        padding-right: 3% !important;
    }

    .nav-cont{
        margin-left: 3% !important;
        margin-right: 3% !important;
    }

    .course-progress-intro-text{
        margin-left: 3% !important;
        margin-right: 3% !important;
    }

}

.grad-1.grad-2{
    top:0px !important;
    z-index: 222;
    width:   100% !important;
    left:0 !important;
    height: 100vh !important;
}

.grad-2{
    background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%) !important;
    transform: rotate(180.00deg) !important;

}

.overlap-group{
    width: 100%;
}

.full-bleed-image{
    top:0 !important;
}

.rectangle-21.rectangle{
    position: absolute !important;
    transform: none !important;
    top:0 !important;
    z-index: 2;
}

.graphic {
    z-index: 2222;
    width: auto !important;
    /* width: 65vw !important;
    height:auto;
    max-width: 803px !important; */
}

.recklessneue-regular-normal-white-100-1px-custom{
    z-index: 222;
}

.rectangle-21.rectangle{
    width: 100%;
}

.grad-for-nav{
    width: 100%;
}

.headline{
    width: auto !important;
}

.about{
    width: 100% !important;
}

.about .homepage{
    width: 100% !important;
}


.about .overlap-group6{
    width: 100% !important;
}

.about .hero-image{
    position: static !important;
    top:auto !important;
    left:auto !important;
    transform: none !important;
    width: 100% !important;
}

.about .overlap-group-1{
    /* transform: rotate(90deg); */
}

.about .overlap-group-1{
    width: 100% !important;
    margin-left: 0 !important;
    position: static !important;
}

.about .full-bleed-image{
    transform:none;
}

.about .overlap-group-item {
    transform:none !important;
    left:0 !important;
    top:0 !important;
    width:  100% !important;
}

.about .about-us {
    transform: none !important;
    position: static;
}


.about .overlap-group-item{
    height: 950px;
}

.about .overlap-group-1{
    max-width: none !important;
    height: 100%;
}

.about .hero-image{
    height: 950px;
}

.about .overlap-group5{
    width: 100%;
}

.about .placeholder_-layout_-blocks{
    width: 100%;
}

.partners .overlap-group3{
    width: auto !important;
}

#header .overlap-group1-1 {
    align-self: flex-start;
    border-radius: 16.55px;
    height: 33px;
    margin-left: 61px;
    position: relative;
    width: 33px;
    margin-top: 0;
}

.about .built-to-flourish{
    width: auto ;
    margin-left: 0;
}

.about .overlap-group5{
    padding: 5vw 10vw 5vw 10vw !important;
}

.about .course-overview-text{
    height: auto !important;
}

.about .homepage{
    height: 729px !important;
}

.about .course-overview-text{
    margin-top: 0;
}

.about .overlap-group6{
    height: 950px !important;
}

.about .overlap-group5{
    margin-top: 0 !important;
}

.about .x03-thumbnail{
    width: 50%;
    max-width: 615px;
}

.about .intro-text-02{
    width: 50%;
    max-width: 582px;
}

.about .right, .about .left{
    min-width: auto !important;
}

.about .lorem-ipsum-dolor-si-2{
    width: 100%;
}

.left .flex-col-2.flex-col-7{
    width: 50% !important;
}

.x03-thumbnail-1{
    width: 50% !important;
    max-width: 615px;
}

.about .lorem-ipsum-dolor-si-3{
    width: auto !important;
    max-width: 582px;
}

.about .course-overview-text-1{
    width: 100% !important;
    max-width: 1200px;
    min-width: auto !important;
}

.about .intro-text-container{
    width: 100%;
    margin: 0;
}

.about .intro-text-01, .about .intro-text-02-1{
    width: 50%;
}

.about .lorem-ipsum-dolor-si-5, .about .lorem-ipsum-dolor-si-4{
    width: 100%;
}

.about .partners{
    max-width: 1200px;
    width: 100%;
}

.about .overlap-group2{
    width: auto;
}

.about .guides{
    max-width: 1200px;
    width: 100%;
}

.about .frame-container, .about .frame-container-1{
    width: 100%;
    min-width: auto;
}

.homepage-main .a-joint-initiative-o {
    z-index: 222;
    width: auto !important;
}

.course-catalogue .overlap-group4 {
    height: auto;
    position: relative;
    width: 100%;
}

.overlap-group5.rect16{
    min-height: auto !important;
    margin-top: -20px !important;
}

.about .overlap-group-item, .about .hero-image, .about .overlap-group6, .about .homepage, .contact .homepage,
.about.courses_ab .homepage
{
    height: 487px !important;
    margin-top: 0 !important;
}

.about.screen.courses_ab{
    height: auto !important;
}


.courses_ab .overlap-group5{
    min-height: auto !important;
}

.course-catalogue .x01 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 77px;
    left: 0;
    min-height: 635px;
    top: 0;
    width: 663px;
}

.course-catalogue .x03-thumbnail {
    align-items: flex-start;
    background-color: var(--geyser);
    border-radius: 10px;
    display: flex;
    margin-left: 2px;
    overflow: hidden;
    width: 613px;
}

.course-catalogue .overlap-group1-1 {
    align-items: flex-start;
    background-image: url(/public/img/pexels-andrea-piacquadio-3760514-1-1.png);
    background-position: 50% 50%;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    min-width: 613px;
    padding: 0 0.1px;
}

.course-catalogue .william-bout-7cd-f-zm-ll-wom-unsplash-1 {
    height: 372px;
    object-fit: cover;
    width: 307px;
}

.top-c{
    display: flex;
    align-items: center;
}

.top-c img{
    height: 372px;
    width: 50%;
    object-fit: cover;
    object-position: left;
}

.course-item{
    border-radius: 4px;
    max-width: 580px;
    width: 50%;
}

.courses-grid{
    margin-top: 50px;
    margin-bottom: 50px;
}

.top-c {
    margin-bottom: 25px;
}

.top-c img:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-right: -1px;
}

.top-c img:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-left: -1px;
}

.c-name{
    letter-spacing: 0;
    line-height: 45px;
    line-height: 44px;
    min-height: 44px;
    white-space: nowrap;
    color: #0a3887;
    font-size: 30px;
    font-family: "Neue Haas Grotesk Display Pro-45Light", Helvetica;
    margin-bottom: 0;
}

.course-item p {
    letter-spacing: -0.44px;
    line-height: 29px;
    margin-top: 10px;

    color: #0a3887;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 20px;
    overflow: hidden;
    max-height: 110px;
    height: 110px;
    text-overflow: ellipsis;
}

.c-details{
    border-radius: 10px;
    padding:16px;
    background-color: #f3e9fa;
    display: flex;
    align-items: center;
}

.c-details{
    font-size: 22.8px;
    color:  #021854;
}

.c-details .it{
    font-style: italic;
    font-weight: 400;
    font-family: var(--font-family-instrument_serif);
    font-size: var(--font-size-s);
}

.c-details .reg{
    color: var(--gulf-blue);
    font-family: var(--font-family-reckless_neue-regular);
    font-size: var(--font-size-s);
    font-style: normal;
    margin-left: 10px;
}

.c-details .c_line{
    margin-left: 20px;
    margin-right: 20px;
    width: 1px;
    height: 25px;
    background-color: #021854;

}

.c_button{
    margin-left: auto;
    width: 46px;
    height:46px;
    border-radius: 50%;
    background-color:  #021854;;
    display: flex !important;
    align-items: center;
    color: #fff;
    text-align: center;
    justify-content: center;

}

.courses-grid {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 30px;
    grid-column-gap: 20px;

}

@media (max-width: 886px) {
    .courses-grid {
        grid-template-columns: 1fr; /* Switch to a single column layout */
        grid-column-gap: 0; /* Remove the column gap */
    }
}


.homepage-main .explore-courses{
    min-height: auto !important;
}

.homepage-main .overlap-group4{
    height: auto !important;
}

.homepage-main{
    height: auto !important;
}

.homepage-main .explore-courses{
    position: static !important;
}


.footer .legatum{
    position: static !important;
}


.footer .legatum_-primary_-logo-1 {
    height: 73px !important;
    width: 99px !important;
}

.about {
    height: auto;
}



.contact .overlap-group5{
    min-height: auto !important;
}

.about .course-overview-text{
    min-width: auto !important;
}

.login.screen{
    width: 100%;
}

.login .overlap-group4{
    width: 100%;
}

.login .hero-image{
    transform: none !important;
    left:0;
    top:0;
    width: 100%;
}

.login .overlap-group1{
    width: 100%;
    height:100vh;
}

.login .full-bleed-image{
    transform: none;
    left:0;
    top:0;
    width: 100%;
}

.login .overlap-group-1{
    width: 100% !important;
    max-width: none !important;
}

.david-marcu-78-a265w-pi-o4-unsplash-1{
    transform : none !important;
    width: 100%;
    object-fit: cover !important;
}

.login .login-module {
    transform: translate(-50%, -50%); /* Center the element */
    left: 50% !important;  /* Position from the horizontal center */
    top: 50% !important;  /* Position from the bottom */
    padding-left: 10px;
    padding-right: 10px;
}

.login .a-joint-initiative-o{
    left: 50% !important;  /* Position from the horizontal center */
    transform: translate(-50%, -50%); /* Center the element */


}



.login.screen, .login .hero-image, .full-bleed-image, .login .overlap-group4, .david-marcu-78-a265w-pi-o4-unsplash-1{
    height: 100vh !important;
}

.login .a-joint-initiative-o{
    bottom:20px;
    top: auto;
}

.course-overview{
    height: auto;
    width:  100%;
}

.course-overview .homepage{
    width: 100% !important;
    height: 616px;
}

.course-overview .overlap-group8 {
    height: 616px;
    margin-top: 0px !important;
    position: relative;
    width: 100%;
}

.course-overview .hero-image{
    top:0;
    width: 100%;
}

.course-overview .overlap-group:not(.nimg){
    width: 100%;
}

.course-overview .rectangle{
    width: 100%;
}

.course-01-why-lead{
    transform:none;
}

.course-overview .headline{
    transform: none;
    top: 0;
    left:0;
    height: auto;
    padding: 0;
    transform: translate(-50%, -50%); /* Center the element */
    left: 50% !important;  /* Position from the horizontal center */
    top: 50% !important;  /* Position from the bottom */
}

.course-overview .page-nav{
    max-width: 1200px;
    width: 100%;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}

.course-overview-text{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.course-overview .overlap-group-1{
    display: block !important;
    height: auto !important;
}

.course-overview .tutors{
    margin-left: 0;
    margin-right: 0;
}

.course-chapters, .course-overview .overlap-group6, .course-overview .qoute-block{
    min-width: auto !important;
    width: 100%;
}

.course-overview .overlap-group9{
    height: auto;
}

.course-overview .overlap-group9{
    width: 100%;
}

.course-overview .following-chapters{
    position: relative;
}

.about .course-overview-text{
    width: 100%;
}

.contact .overlap-group5{
    width: 100%;
}

.course-overview-text{
    max-width: none;
}

.profile-guide{
    width: 100% !important;
}

.profile-guide .overlap-group5{
    width: 100%;
}

.profile-guide .as-seen-on{
    width: 100%;
}

.profile-guide .hero-image{
    position: relative;
    width: 100%;
    transform: none;
    height:431px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right:0;
    left:0;
}

.profile-guide .guide-mike-smith{
    transform: none;
}

.profile-guide .graphic_r{
    transform: none;
}

.profile-guide .graphic_l{
    transform:none;
}

.profile-guide .guide-mike-smith{
    margin-top : 0
}

.profile-guide .graphic_l{
    position: absolute;
    left:0;
    margin-top: 0;
    bottom:0;
}

.profile-guide .graphic_r{
    position: absolute;
    right:0;
    margin-right: 0;
    bottom:0;
}


.profile-guide .overlap-group5{
    height: 431px;
}

.course-progress{
    width: 100%;
}

.course-progress .overlap-group8{
    width: 100%;
}

.course-progress .overlap-group6{
    width: 100%;
}

.course-progress .hero-image{
    width: 100%;
    top:0;
}

.course-progress .overlap-group{
    width: 100%;
}

.course-progress .inbal-marilli-lg-ui{
    width: 100%;
}

.course-progress .rectangle-20{
    width: 100%;
}

.course-progress .headline{
    transform: none;
    top: 0;
    left:0;
    height: auto;
    padding: 0;
    transform: translate(-50%, -50%); /* Center the element */
    left: 50% !important;  /* Position from the horizontal center */
    top: 50% !important;  /* Position from the bottom */
}

.course-progress .overlap-group8{
    height:862px;
}

.course-progress .page-nav{
    width: 100%;
    justify-content: space-between;
    margin-top: 0;
    min-width: auto;
}

.course-progress{
    overflow: hidden;
}

.course-progress .inbal-marilli-lg-ui, .course-progress .overlap-group, .course-progress .hero-image, .course-progress .rectangle-20{
    height: 862px;
}

.course-progress .overlap-group6{
    top:0;
}

.profile-learner{
    width: 100%;
}

.profile-learner .overlap-group8
{
    width: 100%;
    height:  431px;
}

.profile-learner .hero-image{
    position: static !important;
    top:auto !important;
    left:auto !important;
    transform: none !important;
    width: 100% !important;
    height: 431px;
}

.profile-learner .overlap-group{
    width: 100%;
    height: 431px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
}

.profile-learner .learner-saanvi-khatri{
    transform: none !important;
    position: static


}

.profile-learner .layer_4{
    transform: none !important;
    left:0;
    width: 100%;
    top:auto;
    bottom:0;
}

.profile-learner .as-seen-on{
    width: 100%;
}

.profile-learner{
    height: auto;
}

.user-dashboard{
    width: 100%;
}

.user-dashboard .nav{
    width: 100% !important;
}

.homepage-main .explore-courses{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.c-name {
    /*overflow: hidden;*/
    margin-top:15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%; /* Adjust the max-width as needed */
}

.course-overview .homepage{
    min-width: auto;
}

.course-overview .course-overview-text{
    min-width: auto !important;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
}

@media screen and (max-width: 1281px) {
    .page-nav, .profile-learner .flex, .legatum-container  {
        padding-left: 3% !important;
        padding-right: 3% !important;
    }
}

.course-overview{
    overflow:  visible;
}

.course-overview .intro-text-01, .course-overview .overlap-group-1{
    width: auto !important;
    max-width: auto !important;
}

.course-overview .page-nav{
    cursor: auto !important;
}

.instrumentsans-normal-white-26px{
    font-family: inherit !important;
    display: flex;
    align-items: center;
}

.course-overview .intro-text-01{
    margin-top: 0;
}

.course-overview .tutors, .syl-cont{
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}


.course-overview .overlap-group5{
    width: 100%;
    margin-left: 0;
}

.course-overview .overlap-group4{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    top:0;
}

.course-overview .overlap-group3{
    width: auto !important;
    position: relative;

}

.course-overview .the-course-has-reall{
    width: auto;
    max-width: 606px;
    width: 37vw;
    position: static;
    margin-top: 160px;
}

.course-overview .overlap-group6{
    min-height: auto;

}

#footer{
    width: 100%;
}

.course-container {
    align-items: flex-end;
    display: flex;
    gap: 24px;
    height: auto !important;
    min-height: unset;
    margin-top: 10px;
    margin-top: -150px;
    min-width: auto !important;
}

.course-overview .course-chapters{
    height: auto;
}

.course-overview .simbe-kadarake{
    position: static;
    text-align: center;
    margin-top: 10px;
}

.course-overview .elizeu-dias-2-eg-nqazb-a-mk-unsplash-1{
    position: static;
}

.test-2{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.course-overview .courses, .course-overview .following-chapters{
    width: auto !important;
}

.course-overview .following-chapters{
    position: static;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}



.homepage-main .courses, .explore-the-courses{
    max-width: 1200px;
    width: 100%;
}


@media screen and (max-width: 1281px) {
    .homepage-main .courses, .explore-the-courses  {
        padding-left: 3% !important;
        padding-right: 3% !important;
    }
}

.instrumentsans-normal-congress-blue-26px{
    font-family: inherit !important;
}

.footer{
    height: auto !important;
}

.footer .flex-col-2.flex-col-4{
    overflow: hidden;
}

.footer .flex-col.flex{
    padding-right: 10px;
}

.homepage-main .flex-col-3{
    margin-left: 0;
}

.footer .page-links{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    gap: 40px;
    margin-left: 0;
    margin-top: 42px;
}

.footer {
    flex-wrap: wrap;
    justify-content: center !important;
}

.footer .flex-row{
    height: auto    !important;
    flex-wrap: wrap;
}

.footer .group-7{
    margin-left: 0;
    width: 75% !important;
    height: auto !important;
    min-width: 200px;
}

.a-joint-initiative-of.recklessneue-book-normal-white-27-2px{
    margin-left: 0 !important;
}

.flex-col-2.flex-col-4{
    width: 51% !important;
}


@media screen and (max-width: 410px)
{
    .university-logos{
        flex-wrap: wrap;
        justify-content: center !important;
    }
}

@media screen and (max-width: 1100px) {
    /* .course-container{
        display: block !important;
    } */

    /* .course-item{
        width: 94vw;
        margin-bottom: 50px !important;
    } */

}

.homepage-main .explore-the-courses{
    white-space: unset;
}


.mobile_menu, .mobile_toggle{
    display: none;
    color: var(--white);
    font-family: var(--font-family-instrument_sans);
    font-size: 22.7px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    margin-left: auto !important;
}

.mobile_toggle{
    color: #fff;
    letter-spacing: -0.45px;
    line-height: normal;
    margin-bottom: 3px;
    min-height: 28px;
}

@media screen and (max-width: 991px) {
    .drop-bg.active{
        display: none !important;
    }


    .navbar.instrumentsans-normal-white-22-7px{
        display: none;
    }

    .mobile_toggle{
        display: block;
    }

    .navbar-links {
        display: none; /* Hide regular navigation links on small screens */
    }

    .mobile-menu-toggle {
        display: block; /* Show the mobile menu toggle button */
        cursor: pointer;
    }

}


@media screen and (max-width: 820px) {
    .a-joint-initiative-o{
        max-height: 25px;
        height:3.3vw !important;
    }
}

.nav{
    align-items: center !important;
    z-index: 222222;
}

.mobile_menu.active{
    display: block;
    position: absolute;
    right: 0;
    padding-right: 10px;
}

.mobile_menu.active .navbar-link{
    margin-bottom: 10px;
    margin-top: 10px;
}

.mobile_menu.active .overlap-group1-1{
    margin-bottom: 5px;
    margin-top: 20px;
}

.footer a {
    color: inherit
}

.course-overview .intro-text-01{
    width: 45% !important;
    padding-right: 10px !important;
}

.course-overview .overlap-group-1{
    width: 49% !important;
    padding-left: 10px !important;
}

.course-overview .course-overview-text{
    gap:0;
    width: 100%;
}

.course-overview .what-you-will-learn-in-this-course{
    width: 100%;
}

.course-overview .page-nav{
    gap:0 !important;
}

.course-overview .qoute-block{
    padding: 5vw 10vw;
}

.course-overview .overlap-group5{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.course-overview .simbe-kadarake{
    width: auto !important;
}

.course-overview .x01-why-lead-overview{
    width: auto !important;
}

@media screen and (max-width: 988px) {
    .course-overview-text{
        displaY:block !important;
    }

    .course-overview .intro-text-01{
        width: 100% !important;
        padding-right: 0 !important;
    }

    .course-overview .overlap-group-1{
        width: 100% !important;
        padding-left: 0 !important;
        margin-top: 20px;
    }

    .course-overview .page-nav{
        display: block !important;
    }

    .course-overview .course-info-panel{
        max-width: 622px !important;
        width: 100% !important;
        min-width: auto;
    }

    .course-overview .button{
        flex-shrink: 0;
    }

    .course-overview .button{
        margin-left: auto !important;
    }

}

.course-overview .qoute-block{
    height: auto !important;
}

.course-overview .overlap-group3, .course-overview .overlap-group4, .course-overview .overlap-group5{
    height: auto !important;
}

.course-overview .overlap-group3{
    top:0;
}

.course-overview .course-info-panel{
    margin-top:21px;

}
@media screen and (max-width: 808px) {
    .course-overview .overlap-group:not(.nimg){
        background-position: right !important;
        background-size: cover !important;
    }

    .c-details .c_line{
        margin-left: 10px;
        margin-right: 10px;
    }


    .course-overview .access-open, .course-overview .time-1-2-hours{
        width: auto;
        margin-right: 4px;
    }

    .course-overview .frame-21{
        margin-left: 10px;
        gap:10px
    }


    .course-overview .overlap-group4{
        display: block !important;
    }

    .the-course-has-reall.recklessneue-book-normal-congress-blue-48px{
        width: 100%;
        margin-bottom: 20px;
    }

    .course-overview .recklessneue-regular-normal-white-100-1px-custom, .course-overview .instrumentserif-normal-white-100-1px{
        font-size: 14vw;
        line-height: 13vw;
    }

    .course-01-why-lead{
        line-height: 12vw;
    }

}

.course-overview .overlap-group6{
    padding: 5vw 10vw;
}

.course-overview .x01-why-lead-course-chapters{
    white-space: unset;
}

.course-01-why-lead{
    width: auto !important;
}

.course-overview .frame-21{
    min-width: auto !important;
}

.course-overview .a-leader-in-all-of-us{
    white-space: unset;
}

.university-logos {
    /* align-items: flex-start; */
    display: flex;
    gap: 10px !important;
    justify-content: center !important;
    margin-top: 9px;
    min-width: 369px;
    flex-wrap: wrap;

}

.homepage-main .hero-image{
    background-color: transparent !important;
}

.homepage-main .overlap-group2{
    margin-left: 0;
}

.course-01-why-lead{
    margin-right: 0;
}

.course-overview .intro-text-01{
    gap:0
}

.login .overlap-group-1{
    height: 100vh;
}

.david-marcu-78-a265w-pi-o4-unsplash-1{
    position: static;
}

.login .overlap-group3{
    height: 100vh;
    top:0;
}

.login .overlap-group4{
    margin-top: 0;
}

.login{
    margin-bottom: 0;
}

.login .text-30{
    font-family: inherit !important;
}

.login .login-module{
    max-width: 487px;
    width: 100%;
}

.login .email-input{
    max-width: 487px;
    width: 100%;
    min-width: auto !important
}

.login .overlap-group{
    /* width: auto !important; */
    max-width: 487px;
    width: 100%;
}

.login .email-address, .login .password{
    width: 100%;
    overflow: hidden;
}

.login .password-input{
    width: 100%;
    overflow: hidden;
}

.login-module form{
    width: 100%;
}

.login .circled-envelope, .login .eye{
    right:0;
    left:auto;
    background-color: #fff2f2;
    z-index: 22;
}

.login .password-input{
    max-width: 487px;
    width: 100%;
    min-width: auto !important
}

.login .dont-have-an-account-yet-sign-up{
    white-space: unset;
}

.login .button{
    flex-shrink: 0;
}

.login{
    height: 100vh;
}

.courses-grid .course-item{
    width: 100%;
}

.about .legatum_-primary_-logo-1{
    margin:0
}

.quote{
    width: 100%;
    font-family: Instrument Serif;
    font-size: 38px;
    font-style: italic;
    font-weight: 400;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;

    font-size: 38px;
    font-weight: 400;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: left;
    color: #0A3887;
}

.quote-text{
    max-width: 70%;
}

.oxford-logo {
    align-items: flex-start;
    align-self: center;
    background-color: var(--magic-potion);
    display: flex;
    height: 85px;
    margin-bottom: 1px;
    min-width: 85px;
    padding: 6.8px 7.3px;
}

.group_2749 {
    height: 70px;
    width: 70px;
}

.about .about-us{
    width: 100%;
    max-width: 500px;
}

@media screen and (max-width: 520px) {
    .about-us .recklessneue-regular-normal-white-100-1px-custom, .about-us .instrumentserif-normal-white-100-1px{
        font-size: 14vw;
        line-height: 13vw;
    }

    .about .about-us{
        width: 22%;
    }
}

.place {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 43px;
    min-height: 33px;
    white-space: nowrap;
}

.footer .legatum {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 42px;
    min-height: 33px;
    white-space: nowrap;
}

.courses-grid{
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (max-width: 730px) {

}

@media screen and (max-width: 431px) {
    .courses-grid{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }


}

.user-dashboard .nav {
    background-color: #145ec7;
    margin-top:0;
    max-width: none !important;
    /*top: 63px !important;*/
    padding-top:20px !important;
    padding-bottom: 0 !important;
    height:auto
}

.user-dashboard .page-nav{
    width: 100%;
    padding-top: 205px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
}

.nav-cont{
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.navbar.instrumentsans-normal-white-22-7px{
    margin-left: auto !important;
}

.user-dashboard .flex-row{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

}

.inter-light-congress-blue-22px{
    position: relative;
    margin-top:25px;
    margin-left:25px
}

.user-dashboard .overlap-group6{
    width: auto;
    height: auto;
}

.user-dashboard .badges{
    min-width: auto;
    position: relative;
    top:0
}

.user-dashboard .my-achievements{
    min-width: auto;
}

.user-dashboard .overlap-group1-1{
    border:none !important;
}

.user-dashboard .personal-information, .user-dashboard .overlap-group7{
    height: auto;
}

.neuehaasgroteskdisplaypro-light-congress-blue-24px a{
    color: inherit;
}

.user-dashboard .image-9{
    mix-blend-mode: unset;
}

.cred-box{
    width: 142px;
    height: 142px
}

.cred-box img{
    height: 100%;
}

.cred-box div{
    text-align: center;
}

.user-dashboard .badges{
    width: 100%;
    position: static;
}

.user-dashboard .badge_-container{
    width: 100%;
    margin-bottom: 0;
    overflow: auto;
}

.user-dashboard .badge_-container{
    display: grid;
    flex-direction: unset !important;
    gap:19px;
}
.badge_-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the column width as needed */
    gap: 10px; /* Adjust the gap between grid items as needed */
}

.cred-box {
    /* Your styling for .cred-box goes here */
    padding: 10px;
    text-align: center;
}

.user-dashboard .badges{
    height: auto;
}



.courses-in-progress{
    overflow: hidden;
}

.courses-slider{
    display: grid;
    overflow: hidden;
    gap: 40px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.courses-slider .course-item{
    flex-shrink: 0;
    width: 100%;
    max-width: 500px;
}



.user-dashboard .courses-in-progress{
    min-height: auto !important;
    overflow: visible !important;
}

.user-dashboard .line-13-1{
    width: 100%;
}

.user-dashboard .mobile_menu.active{
    background-color: #145ec7;
}

.user-dashboard .courses-in-progress{
    max-width: 1200px;
    width: 100% !important;
}


@media screen and (max-width: 1178px) {
    .courses-slider{
        grid-template-columns: 1fr;
    }

    .courses-slider .course-item{
        max-width: 663px;
        width: 100%;
    }
}


@media screen and (max-width: 800px) {
    .courses-slider .course-item{
        max-width: auto !important;
        width: 500px;
    }
    .user-dashboard .flex-row{
        display: block;
    }

    .user-dashboard .personal-information{
        position: relative;
    }

    .user-dashboard .personal-info{
        background-color: transparent;
    }

    /* .inter-light-congress-blue-22px{
        position: absolute;
        top:0
    } */

    .my-personal-information.inter-light-congress-blue-22px{
        position: absolute;
        top:0
    }

    .user-dashboard .my-achievements{
        margin-top: 20px;
    }

    .badge_-container{
        margin-top:30px;
    }
}

@media screen and (max-width: 620px) {
    .courses-slider .course-item{
        width: 400px;
    }
}


@media screen and (max-width: 540px) {
    .courses-slider .course-item{
        width: 370px;
    }

    .user-dashboard .courses-in-progress{
        padding: 10vw 5vw;
    }

    .user-dashboard .personal-info{
        min-width: auto;
    }

    .user-dashboard .personal-information, .user-dashboard .overlap-group7{
        width: 100%;
    }

    .user-dashboard .title{
        width: 100%;
    }
    .user-dashboard .personal-info{
        min-width: auto !important;
    }

    .email-saanviklwc.neuehaasgroteskdisplaypro-medium-congress-blue-24px span{
        display: block;
        margin-bottom: 10px;
    }

    .user-dashboard .personal-information{
        justify-content: center;
        align-items: center;
    }

    .personal-information img.edit{
        margin-left: auto;
    }

    .user-dashboard .headshot{
        left: 50% !important;  /* Position from the horizontal center */
        top: 220px !important;  /* Position from the bottom */
        transform: translate(-50%, -50%); /* Center the element */
    }

}

@media screen and (max-width: 432px) {
    .user-dashboard .line-13{
        width: 80vw;
    }
}

@media screen and (max-width: 403px) {
    .courses-slider .course-item{
        width: 350px;
    }
}

.harvard-1 {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 42px;
    min-height: 33px;
    white-space: nowrap;
}


.view-public-profile a{
    color: inherit;
}

.user-dashboard{
    height:auto
}

.profile-learner .overlap-group1-1{
    border:none
}

.profile-learner .badges-container{
    width: auto;
    height:auto;
    display: flex;
    gap: 141px;
}

.profile-learner .badges{
    min-width: auto;
}

.profile-learner .badges{
    margin-right: 0;
}

.profile-learner .has-earnt-these-badges{
    position: static;
    width: auto;
}

.profile-learner .badges{
    align-self: flex-start;
}

.learner-saanvi-khatri.recklessneue-book-normal-white-100-1px .c-name{
    color: var(--white);
    font-family: var(--font-family-reckless_neue-book);
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
}

.recklessneue-regular-normal-congress-blue-48px {
    color: var(--congress-blue);
    font-family: var(--font-family-reckless_neue-regular);
    font-size: var(--font-size-xl);
    font-style: normal;
    font-weight: 400;
}


.profile-learner .overlap-group7 .socials{
    position:absolute;
    right:0;
}

.profile-learner .page-nav{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.profile-learner .mentor-bio, .profile-learner .flex, .profile-learner .badges{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.profile-learner .badges-1{
    display:grid;
    position: static;
    align-items: unset;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    flex-grow: 1;
    min-width: auto !important;
    gap:19px;
}

.profile-learner .headshot{
    flex-shrink: 0;
}

.as-seen-on.recklessneue-book-normal-congress-blue-48px .courses-slider{
    margin-top:40px
}

.as-seen-on.recklessneue-book-normal-congress-blue-48px .courses-in-progress{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.profile-learner .badges-container{
    width: 100% !important;
}

.profile-learner .overlap-group7{
    width: 494px;
}

@media screen and (max-width: 1116px) {
    .profile-learner .flex{
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .profile-learner .badges-container{
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap:0;
    }

    .profile-learner .badges-1{
        width: 400px;
        padding-top: 40px;
    }
}

.profile-learner .badges-1{
    overflow: auto;
}


.footer .flex-col.flex{
    margin:0 !important;
}

.profile-learner .footer .flex {
    justify-content: start;
    flex-direction: start;
    align-items: start;
}

.profile-learner .footer .flex-col-4 {
    margin:0;
}


@media screen and (max-width: 670px) {
    .profile-learner .as-seen-on{
        padding: 10vw 5vw;
    }

    .profile-learner .learner-bio{
        width: 100%;
    }
}

@media screen and (max-width: 670px) {
    .profile-learner .overlap-group7{
        width: 420px;
    }

    .learner-saanvi-khatri.recklessneue-book-normal-white-100-1px, .learner-saanvi-khatri.recklessneue-book-normal-white-100-1px span{
        font-size: 14vw !important;
        line-height: 12vw;
    }

}

@media screen and (max-width: 450px) {
    .profile-learner .overlap-group7{
        width: 365px;
    }

    .profile-learner .badges-1{
        width: 375px;
    }

    .profile-learner .headshot{
        width: 350px;
        height:350px;
    }
}

.profile-learner .modules-in-progress{
    white-space: unset;
}

#dashboard-link{
    color: inherit;
}

img.edit{
    cursor: pointer;
    z-index: 222;
}



body.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}

.form-error{
    display: block;
    margin-top:10px;
    font-size: 18px;
    font-family: var(--font-family-inter-medium);
    color: #AF1212;
    display: none;
}

.form-error.show{
    display: block;
}

.course-progress{
    height:auto;
}


.working-main{
    height: auto;
    padding-top:50px;
    padding-bottom: 50px;
}

.course-progress .overlap-group6{
    position: static;
}

.course-progress .overlap-group8{
    height:auto;
}

.nav-cont{
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.course-progress .course-extras-nav{
    width: auto;
}

.working-main{
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.c-outline{
    width: 35%;
}

.c-iframe{
    flex-grow: 1;
}


.level_one_box {
    display: none;
}


.c-outline .children{
    color: var(--congress-blue);
    font-family: var(--font-family-reckless_neue-book);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24.3px;
    margin-top: -1px;
    margin-bottom: 47px;
}

.link-4{
    display: inline-block;
    cursor: pointer;
}

.module_row.sec_lvl.active .link-4{
    border-bottom: 1px solid #0a3887;
}

.chapter-one-what-to-expect{
    width: auto !important;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nav_btn{
    cursor: pointer;
}

.nav_btn.disabled{
    opacity: 0.5;
    pointer-events: none;
}

.arrow-1{
    margin:0;
}

.course-progress .page-nav{
    padding-left: 0;
    padding-right: 0;
}

.bottom-arrows{
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottom_nav{
    width: 46px;
    height:46px;
    border-radius: 50%;
    background-color: #0A3887;
    display: flex ;
    align-items: center ;
    justify-content: center ;
}


.flex-arrows{
    display: flex;
    align-items: center;
    gap:20px;
    line-height: 24.3px;
    margin-bottom: 6px;
    text-align: center;
    color: var(--congress-blue);
    font-family: var(--font-family-reckless_neue-book);
    font-size: var(--font-size-s);
    font-style: normal;
    font-weight: 400;
}

@media screen and (max-width: 991px) {
    .page-nav .nav-cont{
        flex-direction: column;
    }

    .progress-nav{
        justify-content: center;
    }

    .c-outline{
        display: none;
    }

    .course-extras-nav{
        margin-top: 30px;
    }

    .page-nav{
        height:auto !important;
    }
}

@media screen and (max-width: 808px){
    .course-working .course-01-why-lead, .course-working .instrumentserif-normal-white-100-1px, .course-working .recklessneue-regular-normal-white-100-1px-custom {
        line-height: 16vw;
        font-size: 14vw;
    }


    .course-progress .course-extras-nav{
        min-width: auto;
    }


}

@media screen and (max-width: 572px){
    .course-progress .progress{
        margin-left: 13px;
    }

    .course-progress .notes{
        padding:1px 10px;
        width: auto;
    }
}

.course-1.recklessneue-book-normal-congress-blue-22-8px, .instrumentserif-normal-congress-blue-22-8px, .course-extras-nav .recklessneue-book-normal-congress-blue-22-8px{
    border-bottom: 1px solid #0a3887;
    padding-bottom: 6px;
}

.course-progress .place{
    padding-bottom: 0;
}

.course-progress .place{
    line-height: 39.3px;
    margin-top: -6px;
}

.footer .flex-col-2 {
    align-items: flex-start;
    align-self: flex-start;
    min-height: 534px;
    width: 798px;
}

.footer .place {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 38px;
    min-height: 33px;
    white-space: nowrap;
}

@media screen and (max-width: 530px){
    .course-progress .notes {
        padding: 1px 5px;
        width: auto;
    }

    .course-progress .glossary{
        margin-left: 15px;
    }

    .course-progress .course div, .course-progress .progress div, .course-progress .notes div, .course-progress .libary div, .course-progress .glossary div{
        font-size: 4.4vw;
        width: auto;
    }

    .course-progress .course, .course-progress .progress, .course-progress .notes, .course-progress .libary, .course-progress .glossary{
        margin-left: 4vw;
        width: auto;
    }

    .course-progress .course{
        margin-left: 0;
    }

    .page-nav .nav-cont{
        margin:0 !important;
    }

}


/* .course-item p{
    height: 104px;
}

.course-item p{
    max-height: 104px;
    height:auto;
  } */

.course-container{
    display: grid;
    grid-template-columns: 1fr 1fr
}

.course-item {
    width: 100%;
}

.top-c img{
    width: 100%;
}


.course-catalogue.course-container{
    margin-top:0;
    padding-top:50px;
}


.navbar .overlap-group1-1{
    background: none !important;
}

.course-cta{
    min-width: 40px;
    flex-grow: 1;
}

.link-4{
    display: none;
}

.link-44{
    margin-bottom: 40px;
}

.third_lvl.active .link-44{
    border-bottom: 1px solid #0a3887;
    display: inline-block;
}

.progress-nav{
    max-width: 50%;
}

.course-overview .courses-slider-control{
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.course-overview .meet-your-guidesfor-this-course{
    position: static;
    width: auto;
    min-width: 380px
}

.course-overview .badge{
    position: static;
}

.course-overview .overlap-group7{
    display: flex;
    width: 100%;
    gap: 20px
}


.course-overview{
    overflow:hidden
}

.course-overview .tutors{
    margin-top: 50px;
}

.tutors-grid{
    display: flex;
    gap:40px;
    transition: transform 0.3s ease;
}

.courses-slider-control{
    margin-top:70px
}

.course-overview .overlap-group7{
    height:auto;
}

.tutors-full{
    overflow:hidden
}


.previous_tutor{
    rotate : 180deg;

}

.arrow-container .arrow.disabled{
    opacity: 0.5;
    pointer-events: none;
}

.course-overview .courses-slider-control .rectangle {
    transition: width 0.3s ease;
}

@media screen and (max-width: 1210px) {
    .course-overview .overlap-group7{
        flex-direction: column;
    }

    .course-overview .overlap-group-2{
        width: 100%;
    }

    .tutors .flex-row{
        width: 100% !important;
    }

    .course-overview .badge{
        width: 250px;
        min-height: auto;
    }

    .course-overview .frame-30{
        width: 250px;
        height:250px;
    }

    .course-overview .name{
        width: 250px;
    }

}

.profile-guide .headshot{
    margin-left: 0;
}

.profile-guide .mentor-bio{
    gap:0
}

.profile-guide .overlap-group{
    flex-grow: 1;
}

.profile-guide .guide-bio{
    width: 100%;
}

.mentor-bio  .socials{
    position: absolute;
    right:0;
}

.profile-guide .lorem-ipsum-dolor-si{
    width: auto;
}

.profile-guide .headshot{
    flex-shrink: 0;
    margin-right: 40px;
}

@media screen and (max-width: 670px){
    .profile-guide .as-seen-on{
        padding:10vw 5vw;
    }

    .guide-mike-smith.recklessneue-book-normal-white-100-1px{
        font-size: 14vw;
        line-height: 12vw;
    }
}

@media screen and (max-width: 850px){
    .profile-guide .graphic_l, .profile-guide .graphic_r{
        width: 40vw;
    }

    .profile-guide .mentor-bio{
        flex-direction: column;
        gap:40px
    }

    .profile-guide .overlap-group{
        width: 100% !important;
    }
}

@media screen and (max-width: 450px){
    .profile-guide .chris-blonk-bn5cob0k, .profile-guide .headshot{
        width: 70vw;
        height: 70vw;
        margin: auto;
    }
}

.course-extras-nav a {
    color: inherit !important;
}

.course-progress .progress-bar{
    min-width: auto;
}

.course-progress .this-page-shows-your{
    margin-right: 0;
    padding-right: 20px;
    max-width: 998px;
    width: 90%;
}


.legatum-container{
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}


.navbar-link[data-name="login"]{
    display: none;
}

.overlap-group1-1[data-name="user-menu"]{
    display: none;
}

.menu-drop{
    margin-left: 10px;
}

.drop-down{
    /* width: 100%; */
    height: 50px;
    /* background-color: #fff; */
    display: flex;
    align-items: center;
    padding-right: 20px;
}

.navbar{
    margin-bottom: 0 !important;
}

.nav-cont{
    padding-bottom: 15px;
}

.drop-down{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    /* width: 100%; */
    /* left: 0;
    right: 0; */
    color: #fff;
    margin-top: 24px;
    left: 50%;
    transform: translate(-50%, -50%);

}




.drop-down.about-dd{
    width: 396px;
}

.drop-down.contact-dd{
    width: 242px;
}

.screen a{
    color: inherit;
}

.about-drop a{
    font-size: 18px;

}

.about-drop{
    gap: 20px;
    display: flex;
}

.navbar-link {
    position: relative;
}


.drop-bg{
    width: 100%;
    background: #fff;
    height: 51px;
    position: absolute;
    top: 128px;
    z-index: 11;
    display: none;
    left:0;
    right: 0;
}

.drop-bg.active{
    display: block;}

.drop-down{
    display: none;
}

.drop-down.active{
    display: flex;
}


/* .drop-down::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
} */



.the-course{
    padding-top: 60px;
    padding-bottom:60px;
}

.leg-title {
    color: var(--congress-blue);
    font-family: var(--font-family-reckless_trial-light);
    font-size: var(--font-size-xl);
    font-weight: 300;
    letter-spacing: 0;
    line-height: 43px;
    margin-top: 2px;
    min-height: 56px;
    white-space: nowrap;
}

.leg-p{
    color: var(--congress-blue);
    font-family: var(--font-family-neue_haas_grotesk_display_pro-45light);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.48px;
    line-height: 29px;
    margin-top: 20px;

}

.c-section{
    margin-bottom: 60px;
}

.leg-primary-button{
    border-radius: 50px;
    background: var(--cobalt);
    color: #fff;
    font-size: 21px;
    font-weight: 400;
    font-family: var(--font-family-instrument_sans);
    display: inline-block;
    padding:12px 24px;
    cursor: pointer;

}

.items-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.space-bottom{
    margin-bottom: 50px;
}

.overview-box{
    display: flex;
    background-color: #fef6f7;
    border-radius: 10px;
    padding:30px 30px;
}

.space-top{
    margin-top: 40px;
}

.icon{
    display: 35px;
    height:140px;
    width: 140px;
    background-color: #e7d8ec;
    border-radius: 50%;
    border:1px solid #0a3887;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

}

.overview-box svg path {
    fill: #0a3887;
}

.overview-text{
    margin-left: 25px;
}





.overview-box ul {
    list-style-type: disc; /* Use 'disc' for filled circles, 'circle' for empty circles, 'square' for squares, etc. */
    /* You can also use 'list-style: disc;' for shorthand */
}

.about .built-to-flourish{
    text-align: center;
}

@media screen and (max-width: 500px) {
    .overview-box{
        flex-direction: column;
        align-items: center;
    }
}

.c_button{
    color: #fff !important;
}


.course-overview .page-nav{
    height:auto;
}

.course-overview .course-info-panel{
    margin-top: 0;
    margin-bottom: 0;
}

.course-error-message{
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    display: none;
}

.error-box{
    text-align: center;
    color: var(--congress-blue);
    font-family: var(--font-family-neue_haas_grotesk_display_pro-45light);
    font-size: var(--font-size-m);
    font-style: normal;
    font-weight: 300;
    background-color: #FAE9E9;
    border-radius: 10px;
    padding: 20px;
}

.previous_name{
    font-family: var(--font-family-reckless_neue-regular);
}

.headline.recklessneue-regular-normal-white-100-1px-custom span{
    font-family: "Instrument Serif", Helvetica;
    /* font-style: italic; */
    font-weight: 400;
    line-height: 110px !important;
}

.quote{
    background-color: #F3E9FA;
    padding-top:60px;
    padding-bottom:60px;
}

.display-flex{
    display: flex;
}

.test-2 img{
    width: 166px;
    height: 166px;
}

.test-2{
    font-family: Instrument Serif;
    font-size: 27px;
    font-style: italic;
    font-weight: 400;
    line-height: 43px;
    letter-spacing: 0em;
    text-align: center;

}

@media screen and (max-width: 940px) {
    .quote .display-flex{
        flex-direction: column;
        align-items: center;
        gap:20px
    }
}


.course-btn{
    border-radius: 50px;
    background: var(--cobalt-sec);
    color: #fff !important;
    font-size: 21px;
    font-weight: 400;
    font-family: var(--font-family-instrument_sans);
    display: inline-block !important;
    padding: 8px 24px;
    cursor: pointer;
    border:1px solid var(--cobalt) imortant !important;
    letter-spacing: -0.02em;

}



.course-btn.lm{
    border:1px solid var(--cobalt) !important;
    background-color: #fff !important;
    color: var(--cobalt) !important
}

.explore-courses .course-item{
    flex-shrink: 0;
}


.homepage-main .courses, .explore-the-courses{
    overflow: hidden;

}

.homepage-main .rectangle-7{
    width: 0;
}

.previous_course{
    rotate: 180deg;
}

.homepage-main .courses-slider-control, .homepage-main .line-5, .homepage-main .overlap-group-2{
    width: 100%;
}

@media screen and (max-width: 640px) {
    .course-item{
        max-width: 400px;
    }
}

@media screen and (max-width: 430px) {
    .course-item{
        max-width: 380px;
    }
}

.homepage-main .rectangle-7 {
    transition: width 0.3s ease;
}

.course-container{
    transition: transform 0.3s ease;
}

.homepage-main .course-container{
    margin-top: 6px !important;
}

.homepage-main .courses{
    margin-bottom: 150px;
    margin-top: 0 ;
}

.homepage-main .explore-courses{
    gap:0
}

.email-input {
    position: static !important;
}

.footer .overlap-group1-2 {
    position: static !important;
    width: 100%;
    height:auto !important;
}

.footer .sign-up-to-our-newsletter {
    position: static !important;
    width: auto !important;
}

.footer .overlap-group2-1{
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    height: auto !important;
    padding-bottom: 42px;
    align-items: center;
    flex-wrap: wrap;
    gap:11px
}

.footer .button{
    margin-left: 0 !important;
    width: auto !important;
}

.enter-email-adress{
    width: 100%;
    background-color: transparent;
    color: #fff;
    border:none;
    text-align: left;
}

.enter-email-adress::placeholder{
    color: #fff;
}

.enter-email-adress:focus{
    outline: none;
}

.button-1.button-4{
    cursor: pointer;
    flex-shrink: 0;
}

.email-input{
    min-width: auto !important;
}

ul.l-outcome{
    list-style-type: disc;
    margin-left: 20px;
}

.following-chapters .course-container{
    align-items: flex-end;
    display: flex;
    gap: 24px;
    margin-top: 0;
}

.following-chapters .course-item {
    flex-shrink: 0;
}

.course-overview .following-chapters{
    gap:0
}

.following-chapters .courses{
    overflow: hidden !important;
    max-width: 1200px !important;
    width: 100% !important;
}

.course-overview .overlap-group-2, .course-overview .line-5{
    width: 100%;
}

.course-overview .rectangle-7{
    width: 0 ;
}


.footer .button {
    align-items: flex-start;
    border: 1px solid;
    border-color: var(--white);
    border-radius: 50px;
    display: flex;
    height: 50px;
    overflow: hidden;
    padding: 7px 18px;
    width: 307px;
    background-color: transparent !important;
    flex-shrink: 1;
}

.footer .button-1 {
    align-items: center;
    background-color: var(--white);
    height: 46px;
    padding: 0 11px;
    width: 46px;
}

.footer .button-4 {
    border-radius: 50px;
    display: flex;
    overflow: hidden;
    margin-top: 0;
    justify-content: center;
}

.homepage-main .be-a-better-leader-build-a-better-world{
    font-family: Reckless TRIAL-Light;
}

.quote{
    font-family: Reckless TRIAL-Light;
    font-style: normal;
}

.instrumentserif-normal-congress-blue-48px{
    font-size: 27px;
}

.quote .display-flex{
    justify-content: space-between;
}

.explore-the-courses{
    font-family: Reckless TRIAL-Light !important;
}

.enter-email-adress{
    font-family: 'Inter' !important;
}

.instrumentsans-semi-bold-white-24px, .instrumentsans-normal-white-24px, .copyright-2023-leg{
    font-family: 'Inter' !important;
}

.a-joint-initiative-of.recklessneue-book-normal-white-27-2px{
    font-family: Reckless TRIAL-Light !important;
}

.recklessneue-regular-normal-white-100-1px-custom{
    font-family: Reckless TRIAL-Light !important;
    font-style: normal;
}

.about .about-us{
    max-width: 534px;
}

.about .grad {
    background: linear-gradient(180deg, rgb(104.12, 102.82, 102.82) 26.04%, rgba(255, 255, 255, 0) 100%);
    height: 180px;
    /* left: -242px; */
    mix-blend-mode: multiply;
    opacity: 0.75;
    position: absolute;
    /* top: 591px; */
    /* transform: rotate(-90deg); */
    width: 100%;
    z-index: 22222;
    top: 0;
}

.recklessneue-book-normal-congress-blue-48px{
    font-family: Reckless TRIAL-Light !important;
}

.x01-why-lead-course-chapters .instrumentserif-normal-congress-blue-48px{
    font-size: 38px;
}

.span1.course-name{
    font-family: Reckless TRIAL-Light !important;
}

.course-overview .text-1-1{
    font-family: Reckless TRIAL-Light !important;
}

.course-overview .a-leader-in-all-of-us{
    margin-bottom: 0;
}

.course-overview .frame-21{
    margin-bottom: 0;
}



.course-overview .simbe-kadarake{
    font-size: 38px;;
}

.recklessneue-book-normal-congress-blue-22-8px{
    font-family: Reckless TRIAL-Light !important;

}

.course-info-panel .span1 {
    font-family: Reckless TRIAL-Light !important;

}



.course-extras-nav .course div, .place.instrumentserif-normal-congress-blue-22-8px.c-link, .question-title-2, .flex-arrows{
    font-family: Reckless TRIAL-Light !important
}

.guide-mike-smith.recklessneue-book-normal-white-100-1px{
    font-family: Reckless TRIAL-Light !important;
}

.guide-bio .instrumentserif-normal-congress-blue-48px{
    font-size: 38px;
}

.mentor-bio .dflex{
    align-items: flex-start;
}

.guide-bio .span1{
    display: block;
}

.as-seen-on .it, .as-seen-on  .reg, .as-seen-on .c_line{
    visibility: hidden;
}

.profile-guide{
    height:auto !important;
}

.mentor-bio .instrumentserif-normal-congress-blue-48px{
    font-size: 38px;
}

.mentor-bio .recklessneue-regular-normal-congress-blue-48px{
    display: block;
}

.learner-saanvi-khatri.recklessneue-book-normal-white-100-1px{
    font-family: Reckless TRIAL-Light !important;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: fit-content;
}

.profile-learner .c-name{
    font-family: Reckless TRIAL-Light !important;
}

.neuehaasgroteskdisplaypro-light-congress-blue-24px{
    font-family: "Neue Haas Grotesk Display Pro-45Light", Helvetica;

}

.email-saanviklwc.neuehaasgroteskdisplaypro-medium-congress-blue-24px{
    font-family: "Neue Haas Grotesk Display Pro-45Light", Helvetica;
    font-weight: 500;

}

.drop-down.contact-dd.user-menu-dd{
    margin-top: 53px;
}

.logout_btn{
    cursor: pointer;
}



.profile-learner .overlap-group1-1{
    padding:0
}

.user-dashboard .overlap-group1-1{
    padding:0
}

.navbar-link.w-dropdown{
    cursor: pointer;
}

.mobile_menu .navbar-link.w-dropdown{
    display: flex;
    align-items: center;
}

.drop-down.contact-dd.user-menu-dd{
    width: 169px;
}

@media screen and (max-width: 991px) {

    .mobile_menu.active{
        padding-right: 0;
    }

    .drop-down.contact-dd{
        width: auto;
    }

    .menu-drop{
        position: absolute;
        top:10px;
        right: 0;
    }

    .mobile_menu.active{
        width: 140px;
    }


    .navbar-link.w-dropdown:hover > .drop-down{
        display: block;
        position: static;
        background-color: transparent;
        transform:none;
        margin-top: 0;
        height: auto;
        color: #fff;
        font-size: 18px;
        margin-left: 10px;
        padding-right: 0;
        left:-26px;
    }

    .navbar-link.w-dropdown:hover .drop-down.contact-dd.user-menu-dd{
        position: fixed;
        left:-26px;
        width: 100%;

    }

    .user-menu-dd .about-drop{
        padding-top: 40px;
    }


    .about-drop{
        display:block
    }

    .navbar-link.w-dropdown:hover + .about-drop a{
        display: block;
    }

    .about-drop a{
        display: block;
        font-size: 20px;
    }

    .drop-down.about-dd{
        width: auto;
    }

    .navbar-link.w-dropdown:hover{
        display: block;
        margin-left: auto !important;
    }

    .drop-down::before{
        display: none;
    }

}

.user-menu-dd .about-drop{
    padding-right: 55px;
}

.sc-inline-form-error{
    color: #fff;
    margin-top: 10px;
    font-size: 18px;
}

.login-error{
    background-color: #ecd8fb;
    color: #145ec7;
    width: 100%;
    text-align: center;
    height: 66px;
    display: none;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-size: 20px;
    margin-top: 30px;
    border-radius: 10px;
    padding-left: 6px;
    padding-right: 6px;
}

.module_row.third_lvl.disabled{
    opacity: 0.5;
    pointer-events: none;
}

.eye{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color:#2b2b2b;
}

.eye i{
    display: none;
}

.eye i.active{
    display: flex;
}

.personal-information .email-saanviklwc{
    margin-right: auto;
}

.oxford-logo{
    background-color: transparent !important;
}

.university-logos{
    min-width: 369px !important;
    align-items: center !important;
    margin-left: -24px;
}

@media screen and (max-width: 931px) {
    .university-logos{
        min-width: auto !important;
        margin-left:0;
    }
}

.notes-1{
    width: auto !important;
}

.progress{
    margin-left: 0 !important;
}

.course-extras-nav{
    gap: 25px;
    justify-content: flex-end;
}

.course-extras-nav div{
    gap:0 !important;
    padding:0 !important;
    margin:0 !important;
    width: auto !important;
}

.c-nav{
    color:inherit !important;
}

.page-nav .nav-cont{
    padding-bottom: 0;
}

.course-overview .name{
    white-space: normal;
}

.a-nav.active{
    font-weight: 700;
}

.oxford-logo{
    padding:0 !important;
    height:auto !important;
    margin-bottom: 0 !important;
    position: relative;
    top:8px;
}

.university-logos{
    align-items: flex-end !important;
}


.brought-box{
    background-color: #F5F5F5;
    padding:20px
}

.brought-box img{
    max-height:119px;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    width: auto;
    max-width: 100%;
}

.brought-row{
    margin-top: 50px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-start;

}

.brought-line{
    height:1px;
    background-color: #0A3887
;
    width:auto;
    margin-left: 30px;
    margin-right: 30px;

}

.brought-name{
    font-size: 30px;
    line-height: 44px;
    font-family: "Neue Haas Grotesk Display Pro-45Light", Helvetica;
    color: #0A3887;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
}

.brought-box{
    max-width: 565px;
    border-radius: 10.23px;

}

.brought-box p{
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    color: #0A3887;
    font-size: 22.5px;
    font-weight: 300;
    font-family: "Inter", Helvetica;
    line-height: 36.82px;
}

.brought-row{
    display: flex;
    gap:50px;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.brought-box .arrow{
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.b-logo-div{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 179px;
}

.brought-box p{
    min-height: 331px
}

.pres-desc{
    margin-left: auto;
    margin-right: auto;
    height:auto;
    min-height: auto !important;
}


.tutor-list .badge {
    display: flex;
    flex-direction: column;
    gap: 17px;
    min-height: 382px;
}

.tutor-list .badge-01 {
    align-items: center;
    cursor: pointer;
    left: 418px;
    top: 0;
    width: 316px;
}

.tutor-list .frame-30 {
    background-color: var(--snuff);
    background-image: url(/public/img/linkedin-sales-solutions-pata8xe-ivm-unsplash-1-1@2x.png);
    background-position: top;
    background-size: cover;
    border-radius: 200px;
    height: 250px;
    width: 250px;
}

.tutor-list .name {
    white-space: normal;
    letter-spacing: 0;
    line-height: 44px;
    margin-left: 3px;
    min-height: 48px;
    text-align: center;
    white-space: nowrap;
    width: auto;
    font-size: 30px;
}




@media screen and (max-width: 1281px) {
    .brought-row, .tutor-list{
        justify-content: center;
    }
}

.contact-row{
    display: flex;
    gap:40px;
    justify-content: space-between;
}

.c-title{
    max-width: 416px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.c-title .leg-title{
    white-space: initial;

}

.contact-form{
    background-color: #F5F5F5;
    padding: 28px 34px;
    border-radius: 10px;
    flex-grow: 1;
    font-size: 18px;
    color: #0A3887;
    font-family: "Inter", Helvetica;
    line-height: 29px;
    letter-spacing: -0.02em;
}

.contact-form input{
    background-color: #FFF6F6;
    border-radius: 10px;
    border:1px solid #0A3887;
    height:66px;
    width: 100%;
    padding: 15px 24px;
    font-size: 18px;
    color: #0A3887;
    font-family: "Inter", Helvetica;

}




.contact-form textarea{
    background-color: #FFF6F6;
    border-radius: 10px;
    border:1px solid #0A3887;
    height:200px;
    width: 100%;
    padding: 15px 24px;
    font-size: 18px;
    color: #0A3887;
    font-family: "Inter", Helvetica;

}

.form-row label{
    display: block;
    margin-bottom: 12px;

}

.form-row{
    display: block;

}

.form-row:not(:last-child){
    margin-bottom: 27px;
}

.contact-form ::placeholder{
    color:#0A3887;
    font-weight: 300 !important;
}

.contact-form input::placeholder {
    color: #0A3887;
    font-weight: 300 !important;
}

.hr{
    margin-top: 30px;
    background-color: #0A3887;
    width: 100%;
    height: 1px;
    margin-bottom: 24px;
}

.form-button-row{
    display: flex;
    justify-content: flex-end;
}

.contact-btn{
    height: 50px;
    display: flex;
    align-items: center;
    background-color: var(--cobalt);
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    color:#fff;
    font-size: 18px;
    font-family: "Inter", Helvetica;
    cursor: pointer;
}

@media screen and (max-width: 991px) {
    .contact-row{
        flex-direction: column;
        gap:0;
    }

    .c-title{
        max-width: none;
    }
}

.contact-footer p{
    color: #0A3887;
    font-size: 18px;
    font-weight: 500;
    font-family: "Inter", Helvetica;
    margin-bottom: 5px;
}

.f-email{
    text-decoration: underline;
    margin-top: 10px;
}

.f-first-p{
    margin-top: 40px
}

.f-media {
    display: flex;
    gap:16px;
    margin-top: 60px
}

.f-media svg{
    height: 24px;
}

.contact-btn:hover{
    background-color: #1E3F69;
}

.badges-1 a{
    display: block;
}

.w-skeleton{
    display: none;
}

.iframe-loader-skeleton{
    width: 100%;
    height: calc((100vh - 100px) - 62px);
    background-color: #e0e0e0;
    animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #ECECEC;
    }
    100% {
        background-color: #e0e0e0;
    }
}

.link-44{
    cursor: pointer;
    border-bottom: 1px solid transparent;
    display: inline-block;

}

.link-44:hover{
    border-bottom: 1px solid #0a3887;
}
.d-block{
    display: block!important;
}
a.cred-box{
    display: block!important;
}

.success-message{
    margin-top: 14px;
    text-align: center;
    display: none;
}

.intro-video .ellipse-21{
    cursor: pointer;
}

.intro-video-container{
    display: block;
    width: 100%;
    height:100%;
    object-fit: cover;
    display: none;
}

.homepage-main .overlap-group-1{
    background: none;
}

.course-catalogue .course-item{
    display: none;
}

.course-catalogue{
    align-items: flex-start;
}

.skeleton-item{
    display: block;
}

.skeleton-img{
    height: 372px;
    background-color: #e0e0e0;
    width: 100%;
    border-radius: 10px;
    animation: skeleton-loading 1.5s infinite;
}

.skeleton-row, .skeleton-p, .skeleton-details{
    background-color: #e0e0e0;
    animation: skeleton-loading 1.5s infinite;
}

.skeleton-p span{
    visibility: hidden;
}

.skeleton-details span,  .skeleton-details a{
    visibility: hidden;
}

@keyframes skeleton-loading {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #D1D1D1;
    }
    100% {
        background-color: #e0e0e0;
    }
}

.f-bold{
    font-weight: bold;
}

.op-w{
    display: none;
    font-weight: bold;
    font-family: 'Inter' !important;
}

.quote-text{
    font-size: 40px;
    line-height: 50px;
}

.quote .display-flex{
    align-items: center;
}



.drop-down a{
    font-size: 18px !important;
}

.nav {
    transition: background-color 0.3s; /* Smooth transition */
    top:0 !important;
    padding-top:111px !important;
    height:auto !important;
}

.scrolled {
    background-color: var(--cobalt);
}

.navbar-link {
    margin-bottom: 0 !important;
    min-height: 20px !important;
}

.mobile_menu.active .about-drop a{
    margin-bottom: 10px;

}

.nav .mobile_menu.active {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 22px;
}

.nav .mobile_menu.active a{
    margin-left:auto !important;
    display: block;
    padding-right: 75px;
    width: 150px;
}

.nav .mobile_menu.active .overlap-group1-1.navbar-link.w-dropdown{
    margin-left:auto !important;
    display: block;
    padding-right: 75px;
    margin-top:15px !important
}

.nav .mobile_menu .menu-drop{
    margin-right: 47px;
}

.nav.scrolled .mobile_menu.active{
    background-color: var(--cobalt);
    transition: background-color 0.3s;
    padding-bottom: 34px;
}

.mobile_menu .user-menu-dd .about-drop{
    padding-right: 55px;
    text-align: right;
    width: 100%;
    padding-right: 0;
}
.nav.scrolled .mobile_menu  .navbar-link.w-dropdown:hover .drop-down.contact-dd.user-menu-dd{
    background-color: var(--cobalt);
    left: 0;
    right: 0;
    margin-left: 0;
    margin-top: 34px;
}

.nav.scrolled .mobile_menu .user-menu-dd .about-drop{
    padding-top: 7px;
    padding-right: 17px;
}

/*




.nav .navbar-link-courses.navbar-link.w-dropdown, .mobile_menu.active a, .overlap-group1-1.navbar-link.w-dropdown{
    display: block;
    margin-left: auto !important;
    padding-right: 55px;
} */

.about-drop a{
    padding-right: 25px !important;
    margin-bottom: 10px;
}

.homepage-main  .course-item p{
    margin-bottom:0;
}

.homepage-main .courses-slider-control{
    margin-top: 0;
}


.edit-input input.disabled{
    pointer-events: none;
    opacity: 0.8;
    background-color: #D8D8D8;
}

#profileImageInput {
    display: none;
}

.public-profile-image-container{
    cursor: pointer;
}

.link-44{
    font-size: 18px;
}

a.progress-btn{
    display: none;
    color: #0a3887;
}

.hero-image.active {
    filter: blur(5px) !important;
}

.nav_btn.next_btn.btn-remove {
    opacity: 0;
    pointer-events: none;
}


@media only screen and (max-width: 767px) {

    .footer .flex-col-1.flex-col-4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .page-nav{
        margin-top: 10% !important;
    }

    .course-catalogue{
        display: flex;
        flex-direction: column !important;
    }

    .course-overview-text{
        margin-top: 10% !important;

    }

    .home-btn{
        margin-top: 20px;
    }

    .wayfinder-img-container img {
        width: inherit;
        height: 200px;
    }

    .wayfinder-img-container {

        overflow-y: scroll;
    }

    .course-item {
        display: block;
        width: -webkit-fill-available !important;
    }

    .course-container {
        display: flex;
        flex-direction: row;
    }

}


.recklessneue-regular-normal-white-100-1px-custom {
    color: var(--white);
    font-family: var(--font-family-reckless_neue-regular);
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
}

