html{
    background-color: #1e1e1e;
}

html, body{
    height:100%;
}

/* body{ */
    /* background-image: url("/img/william-bout-7cdFZmLlWOM-unsplash 1.png"); */

    /* display: none;
} */

.certificate-container{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    margin: auto;
    position: relative;

}

.overlay-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;


}

.svg-img-overlay{
        position: absolute;
        height: auto;
        z-index: 2;
        width: 100%;
        top: 15%;

}




body{
    margin:0;
    color: #fff
}

h1{
    margin-top: 21px;
    font-family: var(--font-family-neue_haas_grotesk_display_pro-45light);
    font-size: 30px;
    font-weight: 400;

}

.c-logos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.c-logo-image-1{
    width: auto;
    height: 100px;
}

.c-logo-image-2{
    width: 190px;
}


.cert-wrapper{
    width: 100%;
    height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
}

.logo-h{
    margin-top: 42px;
    height:154px;
    width: auto;
    object-fit: contain;
}


.cert-details{
    background-color: #fff;
    color: #0A3887;
    padding: 45px;
    margin-top: 15px;
    border-radius: 10px;
    text-align: center;
    font-size: 15px;
    font-family: var(--font-family-reckless_neue-regular);


}

.user_full{
     max-width: 200px;
}

.user_full, .module_name{
    margin-top: 23px;
    margin-bottom: 17px;
    font-size: 30px;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    line-height: 34px;
    font-family: Reckless TRIAL-Light !important;
}

.module_name{
    margin-bottom: 35px;
}

.j-svg svg, .c-logos svg{
    transform: scale(1.7);
}

.j-svg{
    margin-top: 10px;
}

.l-w-c{
    margin-top: 43px;
    max-width: 400px;
    line-height: 22px;
    font-size: 14px;
}

.website-text{
    font-family: var(--font-family-instrument_serif);
    margin-top: 20px;
    color: var(--cobalt);
font-style: italic;
font-weight: 400;
line-height: 8px;
letter-spacing: 0.01em;
text-align: center;

}

.c-logos{
    margin-top: 40px;
}
.svg-img-overlay-blue{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: #fff;
}
.cert-wrapper.leading-forward{
    width: 80%;
    height: 80%;
    margin: auto;
}

.certificate-container.leading-forward{
 height: auto;
}

.gold-badge {
    position: absolute;
    width: 15%;
    z-index: 14;
    right: 2%;
    top: 2.5%;
}



@media screen and (max-width: 690px) {

    body {
        background-color: #fff;
    }
    .body-overlay{
        background-color: #fff !important;

    }


    .svg-img-overlay {
        width: 100%;
        top: 200px;
        left: 0;
    }

    /*.certificate-container {*/
    /*    background-size: cover;*/
    /*    width: 100%;*/
    /*}*/
    body {
        background-position: top;
        background-size: cover;
    }

    .cert-details{
        max-width: 95%;
        padding:6vw;
    }

    .logo-h{
        height: 22vw;
        margin-top: 5vw;
    }

    h1{
        font-size: 4.5vw;
        margin-top: 4vw;
    }

    .j-svg svg, .c-logos svg{
        transform: scale(1.4);
    }

}


@media (min-width: 1024px) {
    .certificate-container {
        height: 1000px;
        width: 60%;
        margin: auto;
        position: relative;
        overflow: hidden;
    }


    /*.xs-white-color {*/
    /*    background-color:#fff !important;*/
    /*}*/

    /*.svg-img-overlay-blue {*/
    /*    height: 100vh !important;*/
    /*}*/

}


.body-overlay{
    position: fixed;
    top:0;
    bottom:0;
    width: 100%;
    height: 100%;
    background-color: #1e1e1e;
    opacity: 0.5;
    z-index: 1;
    display: none;
}


.link-block-9.w-inline-block {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.download-btn-3.download-cert .link-block.soc.lnkd {
    border-color: var(--cobalt) !important;
    color: var(--cobalt) !important;
    
}

.download-btn-3{
    position: fixed;
    bottom: 20px;
    left: 70%;
    cursor: pointer;
    z-index: 9999;
}

.link-block.soc.lnkd {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #21679d;
    color: #1f77b5;
    background-color: #fff;
}


.download-btn-3.download-cert .social_btn.lnk {
    background-color: var(--cobalt) !important;
    text-align: center;
    color: #fff !important;
    font-weight: 400;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button.social_btn.lnk {
    width: 142.28px;
}

@media print {
    .download-cert {
        display: none;
    }
}


@media print {
  * {
    -webkit-print-color-adjust: exact; /* For Chrome, Safari */
    print-color-adjust: exact; /* For Firefox and other browsers */
  }
}
.c-logos{
    gap: 12px;
}
@media screen and (max-width: 480px) {
    .c-logo-image-1{
        width: calc(100% / 4);
        height: auto;
    }
    .c-logos{
        gap: 7px;
    }

    .c-logo-image-2{
        width: calc(100% / 2);
        height: auto;
    }
    .download-btn-3{
        left: unset;
        right: 0px;
    }
}