@media screen and (max-width: 991px) {
    .leg-p, .leg-title{
        padding: 0 15px;
    }
    .leg-title{
        white-space: break-spaces!important;
    }
}
ul{
    list-style-type: disc!important;
}
.mailto-link{
    text-decoration: underline!important;
    display: inline-block!important;
}
.mailto-link:hover{
    color: var(#0152C1FF) !important;
}