.course_inside_menu {
    z-index: 1;
    background-color: #fff;
    border-top: 1px solid #c7c7c7;
    width: auto;
    height: 100%;
    margin-top: 0;
    padding-top: 0;
    display: flex;
    position: fixed;
    overflow: visible;
    top: 217px;
    max-height: calc(100% - 265px);
    margin-left: calc(3% + 1px);
    border-left: 1px solid #c7c7c7;
}

[dir="ltr"] .course_inside_menu {
    border-right: 1px solid #c7c7c7;
}

.left_m {
    background-color: transparent;
    width: 255px;
    height: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

[dir="ltr"] .left_m,
[dir="ltr"] .left_m_w,
[dir="ltr"] .faq-column-wrapper {
    margin-left: 0;
    padding-left: 0;
}

.faq-column-wrapper {
    margin-top: unset;
}

.faq-question-wrap-3 {
    margin: 0 0 10px;
    padding-bottom: 0;
    line-height: 1.5;
    margin-right: 20px;
}

.faq-question-bar-3 {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    align-items: center;
    width: auto;
    margin-bottom: 0;
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 10px 2px 10px 16px;
}

.div-block-632 {
    background-color: transparent;
    justify-content: flex-start;
    align-items: baseline;
    font-size: 12px;
    display: flex;
    gap: 10px;
}

.question-title-4 {
    font-family: 'Reckless TRIAL-Light', sans-serif;
    font-weight: 400;
    color: rgb(10, 56, 135);
    font-size: 18px;
    line-height: 24px;
}

[dir="ltr"] .question-title-4,
[dir="ltr"] .question-title-4.f {
    float: right;
    margin-left: 5px;
}

.faq-content-3.open {
    height: auto;
    display: block;
}

.course_inside_menu .inner.open {
    cursor: pointer;
    height: auto;
}

.module_row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    font-family: 'Reckless TRIAL-Light', sans-serif;
    font-weight: 400;
    color: rgb(10, 56, 135);
    font-size: 18px;
    line-height: 24px;
    padding: 12px 4px 10px 29px;
}

.module_row:hover,
.module_row.active {
    background-color: #f7f7f7;
}

[dir="ltr"] .module_row:hover,
[dir="ltr"] .module_row.active {
    border-left: 6px solid var(--accent);
}

.course_inside_menu .inner.open .inner_text {
    align-items: center;
    display: flex;
}

.inner_text,
.c_l {
    font-family: 'Reckless TRIAL-Light', sans-serif;
    font-weight: 400;
    color: rgb(10, 56, 135);
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: baseline;
    gap: 5px;
    /* padding: 12px 4px 10px 29px; */
}

[dir="ltr"] .inner_text {
    float: right;
    margin-left: 5px;
}

.c_l {
    color: var(--accent);
}

@media screen and (max-width: 991px) {
    .faq-question-wrap-3 {
        display: none;
    }
}