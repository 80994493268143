/* screen - profile-learner */

.profile-learner {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.profile-learner .overlap-group8 {
    height: 539px;
    position: relative;
    width: 1512px;
}

.profile-learner .hero-image {
    align-items: flex-start;
    background-color: var(--denim);
    display: flex;
    height: 1512px;
    left: 325px;
    position: absolute;
    top: -546px;
    transform: rotate(90deg);
    width: 862px;
}

.profile-learner .overlap-group {
    height: 1512px;
    margin-left: 440px;
    position: relative;
    width: 422px;
}

.profile-learner .learner-saanvi-khatri {
    left: -208px;
    letter-spacing: 0;
    line-height: 88px;
    position: absolute;
    text-align: center;
    top: 644px;
    transform: rotate(-90deg);
    width: 639px;
}

.profile-learner .layer_4 {
    height: 158px;
    left: -413px;
    position: absolute;
    top: 677px;
    transform: rotate(-90deg);
    width: 1512px;
}

.profile-learner .nav {
    align-items: flex-start;
    display: flex;
    gap: 528px;
    height: 95px;
    left: 0;
    padding: 1px 64px;
    position: fixed;
    top: 40px;
}

.profile-learner .navbar {
    align-items: center;
    align-self: center;
    display: flex;
    height: 37px;
    margin-bottom: 16.0px;
    min-width: 564px;
    padding: 1.3px 0;
}

.profile-learner .overlap-group1 {
    align-self: flex-start;
    border-radius: 16.55px;
    height: 33px;
    margin-left: 61px;
    position: relative;
    width: 33px;
}

.profile-learner .ellipse-container {
    height: 19px;
    left: 8px;
    position: absolute;
    top: 7px;
    width: 16px;
}

.profile-learner .ellipse-11 {
    border: 1.32px solid;
    border-color: var(--white);
    border-radius: 6.17px;
    height: 12px;
    left: 2px;
    position: absolute;
    top: 0;
    width: 12px;
}

.profile-learner .ellipse-12 {
    border: 1.32px solid;
    border-color: var(--white);
    border-radius: 16.55px;
    height: 33px;
    left: 0;
    position: absolute;
    top: 0;
    width: 33px;
}

.profile-learner .page-nav {
    flex-direction: column;
    gap: 5px;
    margin-left: 127px;
    margin-top: 37px;
    min-height: 35px;
    padding: 1px 0;
    width: 350px;
    z-index: 22222;
}

.profile-learner .dashboard-your-profile {
    letter-spacing: 0;
    line-height: 24.3px;
    margin-top: 3px;
    white-space: nowrap;
}

.profile-learner .line-6 {
    margin-left: 2px;
    width: 104px;
}

.profile-learner .mentor-bio {
    align-items: flex-start;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-left: 89.0px;
    margin-top: 50px;
    min-height: 372px;
    width: 1259px;
}

.profile-learner .flex-row {
    gap: 141px;
    margin-left: -44px;
    min-width: 1265px;
}

.profile-learner .headshot {
    align-items: flex-start;
    background-color: var(--ebb);
    border-radius: 500px;
    display: flex;
    overflow: hidden;
    width: 272px;
}

.profile-learner .image-9 {
    height: 272px;
    object-fit: cover;
    width: 272px;
}

.profile-learner .overlap-group7 {
    height: 282px;
    margin-top: 18px;
    position: relative;
    width: 752px;
}

.profile-learner .overlap-group-1 {
    height: 282px;
    left: 0;
    position: absolute;
    top: 0;
    width: 752px;
}

.profile-learner .lorem-ipsum-dolor-si-1 {
    left: 0;
    letter-spacing: 0.48px;
    line-height: 29px;
    position: absolute;
    top: 138px;
    width: 752px;
}

.profile-learner .learner-bio {
    left: 0;
    letter-spacing: 0;
    line-height: 54px;
    position: absolute;
    top: 0;
    width: 551px;
}



.profile-learner .button {
    align-items: flex-start;
    background-color: var(--congress-blue);
    border-radius: 50px;
    display: flex;
    height: 50px;
    margin-left: 63px;
    overflow: hidden;
    padding: 7px 8px;
    width: 158px;
}

.profile-learner .connect {
    letter-spacing: -0.52px;
    line-height: normal;
    min-height: 32px;
    text-align: center;
    width: 141px;
}

.profile-learner .badges {
    align-items: flex-end;
    align-self: flex-end;
    background-color: var(--white);
    display: flex;
    height: auto;
    margin-right: -351px;
    margin-top: 0px;
    min-width: 1769px;
    padding: 26.9px 34px;
}

.profile-learner .badges-container {
    height: 425px;
    position: relative;
    width: 1316px;
}

.profile-learner .has-earnt-these-badges {
    left: 0;
    letter-spacing: 0;
    line-height: 54px;
    position: absolute;
    top: 13px;
    white-space: nowrap;
    width: 724px;
}

.profile-learner .badges-1 {
    align-items: flex-end;
    display: flex;
    height: auto;
    left: 489px;
    min-width: 827px;
    padding: 0 12.2px;
    position: absolute;
    top: 0;
}

.profile-learner .badge_-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: -165.22px;
    min-height: 615px;
    width: 172px;
}

.profile-learner .badge_01 {
    align-items: center;
    background-color: var(--snuff);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    justify-content: flex-end;
    min-width: 172px;
    padding: 0 36.6px;
}

.profile-learner .layer_1 {
    height: 98px;
    width: 99px;
}

.profile-learner .badge_04 {
    align-items: flex-start;
    border: 1.09px dashed;
    border-color: var(--nobel);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    margin-top: 47px;
    min-width: 172px;
    padding: 36.0px 36.0px;
}

.profile-learner .badge_08 {
    align-items: flex-start;
    border: 1.09px dashed;
    border-color: var(--nobel);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    margin-top: 50px;
    min-width: 172px;
    padding: 36.0px 36.0px;
}

.profile-learner .badge_-container-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: -165.22px;
    margin-left: 22px;
    min-height: 615px;
    width: 173px;
}

.profile-learner .badge_02 {
    align-items: flex-start;
    background-color: var(--snuff);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    margin-left: 0.46px;
    min-width: 172px;
    padding: 41.5px 38.8px;
}

.profile-learner .layer_1-1 {
    height: 86px;
    width: 87px;
}

.profile-learner .badge_05 {
    align-items: flex-start;
    border: 1.09px dashed;
    border-color: var(--nobel);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    margin-left: 0.46px;
    margin-top: 47px;
    min-width: 172px;
    padding: 40.4px 37.7px;
}

.profile-learner .badge_09 {
    align-items: flex-start;
    border: 1.09px dashed;
    border-color: var(--nobel);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    margin-top: 50px;
    min-width: 172px;
    padding: 40.4px 37.7px;
}

.profile-learner .overlap-group-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: -165.22px;
    margin-left: 21px;
    min-height: 615px;
    width: 173px;
}

.profile-learner .overlap-group5 {
    align-items: flex-end;
    background-color: var(--snuff);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    justify-content: flex-end;
    margin-left: 0.92px;
    min-width: 172px;
    padding: 46.4px 34.9px;
}

.profile-learner .layer_1-2 {
    height: 78px;
    width: 102px;
}

.profile-learner .overlap-group3 {
    align-items: flex-start;
    border: 1.09px dashed;
    border-color: var(--nobel);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    margin-left: 0.92px;
    margin-top: 47px;
    min-width: 172px;
    padding: 46.9px 34.4px;
}

.profile-learner .overlap-group1-1 {
    align-items: flex-start;
    border: 1.09px dashed;
    border-color: var(--nobel);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    margin-top: 50px;
    min-width: 172px;
    padding: 46.9px 34.4px;
}

.profile-learner .overlap-group-container-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: -165.13px;
    margin-left: 25px;
    min-height: 615px;
    width: 174px;
}

.profile-learner .overlap-group4 {
    align-items: flex-end;
    background-color: var(--snuff);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    justify-content: flex-end;
    margin-left: 1.22px;
    min-width: 172px;
    padding: 34.4px 46.9px;
}

.profile-learner .layer_1-3 {
    height: 102px;
    width: 78px;
}

.profile-learner .overlap-group2 {
    align-items: flex-start;
    border: 1.09px dashed;
    border-color: var(--nobel);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    margin-left: 1.22px;
    margin-top: 47px;
    min-width: 172px;
    padding: 34.9px 46.4px;
}

.profile-learner .overlap-group-2 {
    align-items: flex-start;
    border: 1.09px dashed;
    border-color: var(--nobel);
    border-radius: 86.25px;
    display: flex;
    height: 172px;
    margin-top: 50px;
    min-width: 172px;
    padding: 34.9px 46.4px;
}

.profile-learner .as-seen-on {
    align-items: flex-start;
    background-color: var(--blue-chalk);
    display: flex;
    flex-direction: column;
    margin-top: 276px;
    padding: 79px 124px;
    width: 1512px;
}

.profile-learner .completed-modules {
    letter-spacing: 0;
    line-height: 54px;
    margin-bottom: -2px;
    margin-left: 2px;
    min-height: 52px;
    white-space: nowrap;
}

.profile-learner .overlap-group3-1 {
    align-self: center;
    height: 548px;
    margin-left: 1.0px;
    margin-top: 35px;
    position: relative;
    width: 1261px;
}

.profile-learner .x04-text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 643px;
    min-height: 186px;
    top: 362px;
}

.profile-learner .x-lead {
    letter-spacing: 0;
    line-height: 45px;
    margin-top: 23px;
    min-height: 48px;
    white-space: nowrap;
}

.profile-learner .lorem-ipsum-dolor-si {
    letter-spacing: -0.44px;
    line-height: 29px;
    margin-top: 15px;
    min-height: 104px;
    width: 615px;
}

.profile-learner .button-1 {
    align-items: center;
    align-self: flex-end;
    background-color: var(--congress-blue);
    height: 46px;
    margin-right: 31px;
    margin-top: 35px;
    padding: 0 11px;
    width: 46px;
}

.profile-learner .text {
    letter-spacing: -0.52px;
    line-height: normal;
    min-height: 32px;
    text-align: center;
    width: 23px;
}

.profile-learner .thumbnail_02 {
    height: 372px;
    left: 643px;
    position: absolute;
    top: 0;
    width: 618px;
}

.profile-learner .x01-text {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 0;
    min-height: 186px;
    position: absolute;
    top: 362px;
    width: 663px;
}

.profile-learner .lorem-ipsum-dolor-si-2 {
    letter-spacing: -0.44px;
    line-height: 29px;
    margin-top: 15px;
    min-height: 104px;
    width: 620px;
}

.profile-learner .button-2 {
    align-items: center;
    align-self: flex-end;
    background-color: var(--congress-blue);
    height: 46px;
    margin-right: 74px;
    margin-top: 35px;
    padding: 0 11px;
    width: 46px;
}

.profile-learner .x03-thumbnail {
    align-items: flex-start;
    background-color: var(--geyser);
    border-radius: 10px;
    display: flex;
    height: 372px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 613px;
}

.profile-learner .overlap-group-3 {
    align-items: flex-start;
    background-image: url(/public/img/pexels-andrea-piacquadio-3760514-1-1.png);
    background-position: 50% 50%;
    background-size: cover;
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    min-width: 613px;
    padding: 0 0.1px;
}

.profile-learner .william-bout-7cd-f-zm-ll-wom-unsplash-1 {
    height: 372px;
    object-fit: cover;
    width: 307px;
}

.profile-learner .modules-in-progress {
    letter-spacing: 0;
    line-height: 54px;
    margin-bottom: -2px;
    margin-top: 126px;
    min-height: 52px;
    white-space: nowrap;
}

.profile-learner .t-container {
    height: 548px;
    margin-left: 2px;
    margin-top: 35px;
    position: relative;
    width: 664px;
}

.profile-learner .x01-text-1 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 1px;
    min-height: 186px;
    position: absolute;
    top: 362px;
    width: 663px;
}

.profile-learner .button-3 {
    align-items: center;
    align-self: flex-end;
    background-color: var(--congress-blue);
    height: 46px;
    margin-right: 73px;
    margin-top: 35px;
    padding: 0 11px;
    width: 46px;
}

.profile-learner .x04-thumbnail {
    height: 372px;
    left: 0;
    top: 0;
}

.profile-learner .footer {
    align-items: flex-start;
    background-color: var(--cobalt);
    display: flex;
    flex-direction: row;
    flex-shrink: 1;
    gap: 144px;
    height: 786px;
    justify-content: flex-start;
    padding: 103.5px 80px;
    width: 1512px;
}

.profile-learner .flex-col-1 {
    align-items: center;
    margin-left: 41px;
    margin-top: 8.48px;
    min-height: 545px;
    width: 369px;
}

.profile-learner .group-7 {
    height: 297px;
    margin-left: 21.0px;
    width: 302px;
}

.profile-learner .a-joint-initiative-of {
    letter-spacing: 0;
    line-height: 56.9px;
    margin-left: 21.73px;
    margin-top: 83px;
    text-align: center;
    white-space: nowrap;
    width: 239px;
}

.profile-learner .university-logos {
    align-items: flex-start;
    display: flex;
    gap: 46px;
    justify-content: flex-end;
    margin-top: 9px;
    min-width: 369px;
}

.profile-learner .legatum_primary_logo-container {
    height: 89px;
    margin-top: 0.22px;
    position: relative;
    width: 99px;
}

.profile-learner .legatum_-primary_-logo-1 {
    height: 73px;
    top: 0;
}

.profile-learner .legatum_-primary_-logo-2 {
    height: 19px;
    top: 70px;
}

.profile-learner .harvard {
    align-self: flex-end;
    height: 100px;
    margin-bottom: -0.37px;
    width: 85px;
}

.profile-learner .oxford-logo {
    align-items: flex-start;
    align-self: center;
    background-color: var(--magic-potion);
    display: flex;
    height: 85px;
    margin-bottom: 1.0px;
    min-width: 85px;
    padding: 6.8px 7.3px;
}

.profile-learner .group_2749 {
    height: 70px;
    width: 70px;
}

.profile-learner .flex-col-2 {
    align-items: flex-start;
    align-self: flex-end;
    min-height: 534px;
    width: 798px;
}

.profile-learner .overlap-group2-1 {
    height: 111px;
    position: relative;
    width: 781px;
}

.profile-learner .email-input {
    align-items: center;
    display: flex;
    gap: 12px;
    height: 50px;
    left: 401px;
    min-width: 380px;
    position: absolute;
    top: 1px;
}

.profile-learner .button-4 {
    align-items: flex-start;
    border: 1px solid;
    border-color: var(--white);
    height: 50px;
    margin-left: -16px;
    padding: 7px 18px;
    width: 307px;
}

.profile-learner .button-5 {
    align-items: center;
    background-color: var(--white);
    height: 46px;
    padding: 0 11px;
    width: 46px;
}

.profile-learner .overlap-group1-2 {
    height: 111px;
    left: 0;
    position: absolute;
    top: 0;
    width: 749px;
}

.profile-learner .sign-up-to-our-newsletter {
    left: 0;
    letter-spacing: 0;
    line-height: 40px;
    position: absolute;
    top: 0;
    width: 385px;
}

.profile-learner .line-9 {
    left: 5px;
    position: absolute;
    top: 101px;
    width: 744px;
}

.profile-learner .page-links {
    height: 259px;
    margin-left: 4px;
    margin-top: 42px;
    min-width: 794px;
}

.profile-learner .flex-col {
    flex-direction: column;
    margin-top: -1px;
    min-height: 259px;
    width: 246px;
}

.profile-learner .overview {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 43px;
    min-height: 33px;
    white-space: nowrap;
}

.profile-learner .about {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 42px;
    min-height: 33px;
    white-space: nowrap;
}

.profile-learner .brought-to-you-by {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    min-height: 33px;
    white-space: nowrap;
}

.profile-learner .legatum {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 42px;
    min-height: 33px;
    white-space: nowrap;
}

.profile-learner .flex-col-3 {
    align-items: flex-start;
    margin-left: 56px;
    margin-top: -1px;
    min-height: 259px;
    width: 246px;
}

.profile-learner .place {
    letter-spacing: -0.48px;
    line-height: 29px;
    margin-bottom: -3px;
    margin-top: 43px;
    min-height: 33px;
    white-space: nowrap;
}

.profile-learner .flex-row-1 {
    align-items: flex-start;
    display: flex;
    gap: 74px;
    height: 35px;
    margin-left: 5px;
    margin-top: 87px;
    min-width: 744px;
}

.profile-learner .copyright-2023-leg {
    letter-spacing: -0.24px;
    line-height: 29px;
    min-height: 34px;
    white-space: nowrap;
    width: 560px;
}

.profile-learner .vector {
    align-self: center;
    height: 20px;
    margin-top: 0.72px;
    width: 24px;
}

.profile-learner .iconlinkedin {
    height: 29px;
    margin-top: 0;
    width: 30px;
}

.profile-learner .iconyoutube {
    height: 29px;
    margin-top: 0;
    width: 29px;
}

.profile-learner .button-6 {
    border-radius: 50px;
    display: flex;
    overflow: hidden;
}

.profile-learner .flex {
    align-items: flex-start;
    display: flex;
}

.profile-learner .flex-col-4 {
    display: flex;
    flex-direction: column;
}

.profile-learner .line {
    height: 1px;
    object-fit: cover;
}

.profile-learner .x04 {
    position: absolute;
    width: 615px;
}




.socials a[data-name="email"]{
    display: none;
}


.profile-learner .overlap-group7 .socials{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
}

.learner-profile-edit{
    display: none;
}

#edit-popup.active {
    display: flex;
}

#edit-popup {
    z-index: 2222222;
    background-color: #ffffffe6;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: none;
}

.edit-profile {
    background-color: var(--cultured-pearl);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 90vh;
    overflow: hidden;
    padding: 28px 28px;
    position: static;
    max-width: 738px;
    width: 90vw;
    overflow: auto;
}

.edit-profile .top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--congress-blue);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 300;
    border-bottom: 1px solid var(--congress-blue);
    padding-bottom: 20px;
}

.edit-input {
    padding-top: 20px;
}

.edit-input label {
    display: block;
    margin-bottom: 15px;
    color: var(--congress-blue);
    font-family: var(--font-family-inter-medium);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.44px;
    line-height: 29px;
    white-space: nowrap;
}

.edit-input input {
    width: 100%;
    padding: 13px 23px;
    border-radius: 10px;
    background-color: var(--rose-white);
    outline: unset !important;
    border: 1px solid var(--congress-blue);
    font-size: 22px;
    height: 66px;
    color: var(--congress-blue);
    font-family: var(--font-family-inter-medium);
}

.em-display {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-top: 0;
    margin-bottom: -60px;
    z-index: 222;
}

.em-display label {
    margin-bottom: 0;
}

.em-display label {
    color: var(--congress-blue);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 300;
}
.edit-input label {
    display: block;
    margin-bottom: 15px;
    color: var(--congress-blue);
    font-family: var(--font-family-inter-medium);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.44px;
    line-height: 29px;
    white-space: nowrap;
}

#displayProfile {
    width: 31px;
}

.edit-input input {
    width: 100%;
    padding: 13px 23px;
    border-radius: 10px;
    background-color: var(--rose-white);
    outline: unset !important;
    border: 1px solid var(--congress-blue);
    font-size: 22px;
    height: 66px;
    color: var(--congress-blue);
    font-family: var(--font-family-inter-medium);
}


.edit-input textarea {
    width: 100%;
    padding: 13px 23px;
    border-radius: 10px;
    background-color: var(--rose-white);
    border: 1px solid var(--congress-blue);
    font-size: 22px;
    color: var(--congress-blue);
    font-family: var(--font-family-inter-medium);
}

.edit-profile .bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--congress-blue);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 300;
    border-top: 1px solid var(--congress-blue);
    padding-top: 20px;
    margin-top: 20px;
}
.close-edit-popup {
    cursor: pointer;
}

.cancel {
    align-items: flex-start;
    align-self: flex-end;
    border: 1px solid;
    border-color: var(--congress-blue);
    border-radius: 50px;
    display: flex;
    height: 50px;
    overflow: hidden;
    padding: 6px 29px;
    width: 131px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.save {
    align-items: center;
    background-color: var(--midnight-blue);
    border-radius: 50px;
    cursor: pointer;
    height: 50px;
    padding: 0 7px;
    width: 156px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.em-display label {
    margin-bottom: 0;
}

.headshot.public-profile-image-container{
    visibility: hidden;
}
.profile-learner .courses-in-progress {
    max-width: 1200px;
    width: 100% !important;
    align-items: flex-start;
    background-color: var(--cultured-pearl);
    border-radius: 10px;
    display: flex
;
    flex-direction: column;
    gap: 19px;
    margin-top: 105px;
    min-height: 701px;
    padding: 63.5px 67.6px;
    width: 1256px;
}
.cred-box img{
    max-width: 172px;
    max-height: 172px;
}
.badges .cred-box{
    width: 172px;
    padding: 0 0 10px 0;
}
.profile-learner .learner-bio-block{
    margin-right: unset!important;
    margin-left: unset!important;
    width: 100% !important;
}
@media screen and (max-width: 768px) {
    .profile-learner .learner-bio-block{
        gap: 30px!important;
    }
}
.profile-learner .overlap-group7{
    width: 100% !important;
}
@media screen and (max-width: 480px) {
    .profile-learner .courses-in-progress {
        padding: 40px 10px;
    }
}
@media screen and (max-width: 480px) {
    .profile-learner .badges-1 {
        width: auto!important;
    }
}