.success-message{
    display: block!important;
}

.form-error-custom{
    display: block !important;
}
@media screen and (max-width: 768px) {
    .contact-footer.desktop-view{
        display: none;
    }
    .contact-footer.mobile-view{
        display: block;
    }
    .contact-form input{
        font-size: 16px!important;
    }
}
@media screen and (min-width: 769px) {
    .contact-footer.desktop-view{
        display: block;
    }
    .contact-footer.mobile-view{
        display: none;
    }
}