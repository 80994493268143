@media screen and (max-width: 991px) {
    .course-catalogue .course-item p{
        max-height: none;
        height: auto;
    }
    .c-details .reg{
        margin-left: 1px!important;
    }
    .c-details{
        flex-wrap: wrap!important;
    }
    body .course-overview-text{
        margin-top: 0px!important;
    }
    .user-dashboard .page-nav{
        padding-top: 78px!important;
    }
    .course-overview .recklessneue-regular-normal-white-100-1px, .course-overview .instrumentserif-normal-white-100-1px{
        white-space: normal!important;
    }
    .course-overview .course-info-panel{
        padding: 14px 9px!important;
    }
    body .course-overview .page-nav-custom{
        margin-top: 30px!important;
    }
    .course-overview .flex-col-1, .course-overview .page-nav-custom{
        margin-bottom: 10px!important;
    }
    .course-overview .course-info-panel{
        height: auto!important;
        flex-wrap: wrap;
    }
    .course-overview .homepage{
        height: 350px!important;
    }
    .course-overview .overlap-group{
        height: 100%!important;
    }
    .course-overview .rectangle, .course-overview .hero-image{
        height: 100% !important;
    }
    .unit-number{
        text-align: left!important;
        margin-right: 15px!important;
    }
    .unit-content{
        width: 100% !important;
        align-items: start!important;
        line-height: 23px!important;
    }
    .unit-item .unit-dot::after{
        bottom: -56px!important;
    }
    .course-overview .a-leader-in-all-of-us{
        font-size: 20px;
        line-height: 14px!important;
    }
    .course-progress .page-nav{
        padding: 17px 127px!important;
    }
    .wayfinder-img-container img{
        height: auto!important;
        width: 100%!important;
    }

    .overview-box .overview-text{
        margin-top: 15px!important;
    }
    .overview-box .overview-text .ov-title{
        line-height: 33px!important;
    }
    .overview-box li{
        margin: 16px 20px!important;
        line-height: 30px;
        font-size: var(--font-size-m);
    }
    body .headline .course-name{
        line-height: normal!important;
        max-height: none!important;
    }
    .course-overview .headline{
        height: 100% !important;
        justify-content: center;
    }
    .top-c img{
        object-position: top!important;
    }

    .nav .mobile_menu.active .overlap-group1-1.navbar-link.w-dropdown{
        position: absolute!important;
        top: 0px;
        left: 30px;
    }
    .unit-number{
        min-width: 30px;
        width: 30px!important;
    }
    .frame-21-custom{
        margin-bottom: unset!important;
    }
    .frame-21-custom .unit-item{
        padding: 20px 20px 0 20px;
    }

}
.unit-dot{
    min-width: 10px;
}
.overview-box *{
    font-family: var(--font-family-neue_haas_grotesk_display_pro-45light) !important;
}
.user-dashboard .my-achievements{
    max-height: 560px;
    overflow: auto;
    padding-bottom: 30px;
}
.cred-box div{
    color:var(--cobalt)
}
.w-100{
    width: 100%!important;
}
.c-details .c_line{
    margin: 0 10px!important;
}
.c-details .reg{
    margin-left: 3px!important;
}
.c-details .reg{
    font-size: 14px!important;
}
.c-details .it{
    font-size: 15px!important;
}
:root{
    --sl-z-index-dialog:99999!important;
    --sl-z-index-toast:99999999!important;
}
.location-dialog::part(title){
    color: var(--cobalt-2) !important;
}
.location-dialog::part(body){
    color: var(--congress-blue) !important;
    height: 100%;
    overflow: unset;
}
.location-dialog::part(panel){
    max-height: unset!important;
    height: auto;
}


.react-select__control{
    width: 100%;
}
.react-select-container{
    width: 100%;
}

/*
.react-select__menu{
    background-color: #21252b!important;
    z-index: 9999999999!important;
}
.react-select__single-value{
    color: white!important;
}
.react-select__option:hover, .react-select__option--is-focused, .react-select__option--is-selected{
    background: #527bcd!important;
}
.react-select__control{
    background-color: inherit!important;
}
.react-select__control{
    border-style: solid!important;
    border-color: #26293b!important;;
    border-radius: 4px!important;;
}*/

.user-location-form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0px;
}
.user-location-form-container{
    flex-direction: column;
    display: flex;
    gap: 10px;
}
.user-location-form-container.city-container{
    margin-top: 50px;
}
.react-select__menu{
    margin-top: 45px!important;
}

sl-button.legatum-btn::part(base){
    background-color: var(--cobalt-2) !important;
    color: #FFF!important;
}
sl-button.legatum-btn::part(base):hover{
    background-color: var(--cobalt) !important;
}
.top-c img{
    height: unset!important;
}