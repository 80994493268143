._5daychallenge-block{
    margin-top: 15px;
    background-color: transparent!important;
    border: solid 4px #f3e9fa;
}
._5daychallenge-icon-block{
    width: 46px;
    height: 46px;
    background-color: #f3e9fa;
    border-radius: 50%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

}
._5daychallenge-icon-block.more-m-r{
    margin-right: 14px;
}
._5daychallenge-icon-block img{
    width: 33px;
}
._5daychallenge-banner{
    width: 60px;
    height: 100px;
    position: absolute;
    display: flex!important;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 0px;
    align-items: center;
    align-content: center;
    right: 10px;
    top: 0px;
    color: var(--cobalt) !important;
    background-color: #FFF4F3;
    border-radius: 0px 0px 60px 60px;
    gap: 5px;
}
.course-item{
    position: relative;
}
._5daychallenge-banner-header{
    font-size: 18px;
}
._5daychallenge-banner-desc{
    font-size: 10px;

}
._5daychallenge-banner-details-block{
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-instrument_serif);
    font-style: italic;
}