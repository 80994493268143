.course-container .react-multiple-carousel__arrow{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--cobalt-sec);
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  z-index: 10000;
  opacity: 1;
  border: none;
}
.custom-arrow-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--cobalt-sec);
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  z-index: 10000;
  opacity: 1;
  border: none;
  cursor: pointer;
}
.custom-arrow-btn:hover{
  background-color: var(--cobalt);
}
.custom-arrow-btn img{
  filter: invert(1);
  width: 30px;
}
.custom-arrow-btn.__right{
  right: 0;
}
.custom-arrow-btn.__left{
  left: 0;
}


.course-container .react-multiple-carousel__arrow--left {
  left: 0px; /* Adjust as needed */
}

.course-container .react-multiple-carousel__arrow--right {
  right: 0px; /* Adjust as needed */
}

.course-container {
  position: relative;
  overflow: hidden;
  direction: ltr;
  width: 100% !important;
}
.course-container::before,
.course-container::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100px; /* Adjust the width as needed */
  background: linear-gradient(to left, rgba(255, 255, 255, 0.9), transparent);
  pointer-events: none; /* So it doesn't interfere with arrow interaction */
}

.react-multi-carousel-item {
    max-width: 500px !important;
}
@media screen and (max-width: 768px) {
  .custom-arrow-btn{
    top: 31%;
  }
}
.course-item-desc{
  color: #0a3887;
  font-family: Inter, Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  height: 110px;
  letter-spacing: -.44px;
  line-height: 29px;
  margin-bottom: 20px;
  margin-top: 10px;
  max-height: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-width: 991px) {
  .course-container::before, .course-container::after{
    background: linear-gradient(to left, rgba(255, 255, 255, 0.1), transparent) !important;
  }
  .carousel-course-item .course-item-desc{
    height: auto!important;
    max-height: none!important;
  }
}