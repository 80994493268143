.frame-21-custom {
  display: flex;
  width: 100%;
  margin-bottom: 10px; /* Add space between stacked items */
}

.course-info-panel-2 .overlap-group-custom {
  display: flex;
  flex-direction: column;
}

.unit-item {
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
  align-items: flex-start;
}

.unit-number {
  margin-right: 15px;
    width: 50px; /* Set a fixed width to prevent larger numbers from pushing content */
  text-align: right; /* Align numbers to the right for better presentation */
    margin-right: 20px; /* Add 20px space between the number and the dot */

}

.unit-content {
  display: flex;
  align-items: center;
  position: relative;
}

.unit-dot {
  width: 10px;
  height: 10px;
  background-color: #2e3b8f; /* Dark blue dot */
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
    margin-right: 20px; /* Add 20px space between the dot and the unit title */

}

.unit-title {
}

/* Vertical line connecting dots */
.unit-item .unit-dot::after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #2e3b8f;
  top: 50%; /* Start the line at the middle of the dot */
  bottom: -85px; /* Extend the line down */
  left: 50%;
  transform: translateX(-50%);
}

/* Remove line for the last dot */
.frame-21-custom:last-child .unit-item .unit-dot::after {
  display: none;
}